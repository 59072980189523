import React from "react";

function TimeDifference({ currentTime, dateString }) {
  const calculateTimeDifference = () => {
    const postedTime = new Date(dateString);
    const differenceInMilliseconds = currentTime - postedTime;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

    if (differenceInSeconds < 60) {
      return "Just now";
    } else if (differenceInSeconds < 3600) {
      const minutes = Math.floor(differenceInSeconds / 60);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (differenceInSeconds < 86400) {
      const hours = Math.floor(differenceInSeconds / 3600);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (differenceInSeconds < 2592000) {
      const days = Math.floor(differenceInSeconds / 86400);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (differenceInSeconds < 31536000) {
      const months = Math.floor(differenceInSeconds / 2592000);
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      const years = Math.floor(differenceInSeconds / 31536000);
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  };

  const timeDifference = calculateTimeDifference();

  return <div>{timeDifference}</div>;
}

export default TimeDifference;
