import "../../shimmer.css";
const CompanyInfoShimmer = () => {
  return (
    <div className="w-full space-y-3">
      <div className="animate-pulse-gradient w-[270px] h-4 rounded-full"></div>
      <div className="w-full space-y-4 sm:space-y-0 p-5 bg-white rounded-md shadow flex flex-wrap flex-col sm:flex-row justify-center sm:justify-between items-center">
        <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row items-center  sm:space-x-5">
          <div className="w-[100px] h-[100px] rounded-full overflow-hidden shadow-md animate-pulse-gradient"></div>
          <div className="space-y-4 flex flex-col sm:justify-center items-center sm:items-start">
            <div className="w-[150px] md:w-[250px] h-3 rounded-full  animate-pulse-gradient"></div>
            <div className="w-[100px] md:w-[200px] h-3 rounded-full  animate-pulse-gradient"></div>
          </div>
        </div>
        <div className="h-[60px] flex items-center justify-center min-w-max w-full sm:w-auto">
          <div className="w-full max-w-[300px] sm:w-[200px] h-10 rounded animate-pulse-gradient"></div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfoShimmer;
