const FAQPage = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="w-[97%] max-w-[1000px] sm:bg-slate-100 flex flex-col space-y-8 p-4 sm:p-7 md:p-10 my-12 rounded-xl">
        <span className="text-2xl font-bold">Frequently Asked Questions.</span>
        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">What is iChereta?</span>
          <span>
            iChereta is an online platform that aggregates and delivers tender
            notices from various sources across Ethiopia. We provide a
            one-stop-shop for businesses and individuals seeking to explore
            opportunities in the Ethiopian market.
          </span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">
            What are the sources of tenders?
          </span>
          <span>
            We source information from a variety of authenticated sources such
            as newspapers, magazines, and the websites of relevant
            organizations.
          </span>
        </div>

        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">
            What payment options are available?
          </span>
          <span>
            We offer two payment options for our subscription packages: bank
            transfer and Telebirr. You can pay through the following banks: -
            Commercial Bank of Ethiopia (CBE) If you have any other questions or
            concerns, please don't hesitate to reach out to us.
          </span>
        </div>

        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">
            How often is the tender database updated?
          </span>
          <span>
            We collect data from various sources and upload it daily. Our team
            ensures accuracy of the data.
          </span>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;
