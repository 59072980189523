import { useContext } from "react";

import context from "../store/context";
const FreeTrialAdBanner = () => {
  const { HandleSubscription, isFetchingInvoice, userInfo } =
    useContext(context);
  const userToken = localStorage.getItem("token");
  return (
    <>
      {userToken && userInfo?.latest_plan_type === "WELCOME_TRIAL" ? (
        <div class="flex  justify-between items-center w-full bg-white p-4 border-2 border-orange-400 rounded-lg">
          <div class="text-left w-[60%] border-r border-[#607d8b36] pr-2 flex flex-col gap-2">
            <h2 class=" text-sm sm:text-md font-bold ">
              የ <span className="text-base">{userInfo?.plan_duration}</span> ቀን
              ነፃ ሙከራ ጊዜ ላይ ኖት።
            </h2>
            <p class="font-bold text-xs text-[#3d5984]">
              የአገልግሎት መቆራረጥ እንዳያጋጥሞ እባክዎ የአባልነት ክፍያ ይፈጽሙ፣ ያለ ምንም ገድብ ሁሉንም ጨረታዎች
              መመልከት ይችላሉ።{" "}
            </p>
          </div>
          <div class="text-[#3d5984] flex-col items-center  text-right">
            <div class="mb-2  text-center">
              <span class="font-semibold text-xs sm:text-sm text-orange-500 text-center">
                የ <span className="text-base">{userInfo?.plan_duration}</span>{" "}
                ቀን ነፃ ሙከራ ጊዜዎ <br />{" "}
                <span className="text-base">{userInfo?.plan_days_left}</span> ቀን{" "}
                <br />
                ብቻ ቀርቶታል።
              </span>
            </div>

            <div className="px-2">
              <button
                onClick={HandleSubscription}
                className={`py-1.5 animate-button text-white shadow w-[100%] rounded relative text-center mx-auto text-lg font-bold px-4`}
              >
                <span className="text-semibold text-sm">ክፍያ ይፈፅሙ!</span>
                {isFetchingInvoice && (
                  <div className="absolute top-0 left-0 w-full h-full rounded bg-black/40 flex items-center justify-center">
                    <div className=" animate-spin border-t border-white w-5 h-5 rounded-full"></div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default FreeTrialAdBanner;
