import { useContext } from "react";
import { Link } from "react-router-dom";

// context
import context from "../../store/context";

// section components
import SideBanner from "../../components/section/SideBanner";

// images
import Logo from "../../assets/logo.svg";

const AuthenticationLayout = (props) => {
  const { finishedRegistration } = useContext(context);
  return (
    <div className="w-full flex">
      <SideBanner />

      <div className="flex flex-col w-full min-h-screen lg:w-auto flex-grow pb-6 lg:pb-0 lg:py-6 sm:bg-blue-50/40">
        <div className="w-full flex justify-center shadow  lg:hidden">
          <div className="w-[90%] flex items-center justify-between py-2 sm:py-3">
            <Link to="/">
              <img src={Logo} alt="logo of ichereta" className="w-28" />
            </Link>
            <Link to={finishedRegistration ? "/tenders" : props.route}>
              <button className="text-[#664bc7]  py-1.5 text-sm rounded-full w-[90px]">
                {finishedRegistration ? "Tenders" : props.button}
              </button>
            </Link>
          </div>
        </div>
        <div className="w-full h-full flex flex-col">
          <div className="w-full lg:h-[55px] bg-gradient-to-r from-transparent to-slate-100/50 lg:pr-20 hidden lg:flex items-center justify-center lg:justify-end space-x-10">
            <h1>
              {finishedRegistration ? "Browse latest tenders" : props.message}
            </h1>
            <Link to={finishedRegistration ? "/tenders" : props.route}>
              <button className="border border-[#664bc7]  text-[#664bc7]  py-2 text-sm rounded-full w-[100px]">
                {finishedRegistration ? "Tenders" : props.button}
              </button>
            </Link>
          </div>

          {props.children}
        </div>
        <div className="flex flex-col items-center  text-sm text-[#beb4e1] w-full lg:hidden">
          <span>© 2023 &mdash; iChereta™</span>
          <h1 className="font-semibold">
            <span className="font-normal">Powered by </span> HahuCloud
            Technologies PLC
          </h1>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationLayout;
