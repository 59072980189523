import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//context
import context from "../../store/context";

import DateConverter from "../DateConverter";
import TimeDifference from "../TimeDifference";
import SaveUnsave from "../Buttons/SaveUnsave";

//icons
import CalendarIcon from "../icons/Calendar";

const TenderCard = ({
  title,
  openingDate,
  closingDate,
  createdAt,
  id,
  status,
  isSavedDB,
  currentPath,
  alternativeOpeningDate,
  alternativeClosingDate,
  publishedOn,
}) => {
  const currentTime = new Date();

  const { setPrevRoute } = useContext(context);

  const navigate = useNavigate();

  const location = useLocation();

  const telegramPage =
    location.pathname.startsWith("/latest-tenders/telegram") ||
    location.pathname.startsWith("/myfeed/telegram");
  const telegramDetail = location.pathname.startsWith("/tender/telegram");

  const openDetailPage = () => {
    setPrevRoute(telegramDetail ? "/latest-tenders/telegram" : currentPath);

    if (telegramPage) {
      navigate(`/tender/telegram/${id}`);
    } else {
      navigate(`/tender/${id}`);
    }
  };

  const redirect = () => {
    navigate("/login");
  };

  const userToken = localStorage.getItem("token");
  const notAUser = "Login or Subscribe";

  return (
    <div className="w-full flex flex-col bg-white rounded-md py-4 px-5 space-y-2 shadow text-[#3d5984]">
      <button
        onClick={openDetailPage}
        className="w-full flex flex-col items-start"
      >
        <div className="border-b border-b-gray-200/80 pb-2 text-sm sm:text-base font-semibold text-start w-full">
          <h1>{title}</h1>
        </div>
        <div className="flex flex-col space-y-2 text-xs sm:text-sm items-start pt-2">
          <div className="flex items-start space-x-2">
            <h1 className="font-bold min-w-max">Bid Closing Date:</h1>

            {userToken ? (
              alternativeClosingDate ? (
                <span className="text-start">{alternativeClosingDate} </span>
              ) : closingDate ? (
                <DateConverter dateString={closingDate} />
              ) : (
                ""
              )
            ) : (
              <span>{notAUser}</span>
            )}
          </div>
          <div className="flex items-start space-x-2">
            <h1 className="font-bold min-w-max">Bid Opening Date: </h1>

            {userToken ? (
              alternativeOpeningDate ? (
                <span className="text-start">{alternativeOpeningDate} </span>
              ) : openingDate ? (
                <DateConverter dateString={openingDate} />
              ) : (
                ""
              )
            ) : (
              <span>{notAUser}</span>
            )}
          </div>

          <div className="flex items-start space-x-2">
            <h1 className="font-bold min-w-max">Published On: </h1>
            {userToken ? (
              publishedOn ? (
                publishedOn.map((publisher, index) => {
                  return (
                    <div
                      className="text-start flex items-start space-x-2"
                      key={publisher.source.id}
                    >
                      {index !== 0 && <span>,</span>}
                      <span>{publisher.source.name}</span>

                      <div className="flex">
                        <span>{`(`}</span>
                        <DateConverter dateString={publisher.published_at} />
                        <span>{`)`}</span>
                      </div>
                    </div>
                  );
                })
              ) : (
                ""
              )
            ) : (
              <span>{notAUser}</span>
            )}
          </div>
        </div>
      </button>
      <div className="flex flex-wrap items-center justify-between border-t border-t-gray-200/80 pt-2">
        <div className="space-x-2 md:space-x-6 text-xs sm:text-sm flex  items-center">
          <div
            onClick={openDetailPage}
            className={`flex items-center font-semibold space-x-2 ${
              Number(status) === 1 ? "text-green-600 " : "text-red-500 "
            }  bg-opacity-40 px-2 py-1 text-xs`}
          >
            <CalendarIcon styles="w-5 h-5" />
            {Number(status) === 1 ? (
              <span>BIDDING OPEN</span>
            ) : (
              <span>BIDDING CLOSED</span>
            )}
          </div>

          {!telegramPage && (
            <div className="flex items-center space-x-2">
              <SaveUnsave
                navigate={redirect}
                isSavedDB={isSavedDB}
                id={id}
                currentPath={currentPath}
              />
            </div>
          )}
        </div>
        <span className="italic text-xs sm:text-sm font-thin">
          <TimeDifference currentTime={currentTime} dateString={createdAt} />
        </span>
      </div>
    </div>
  );
};

export default TenderCard;
