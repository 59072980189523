import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

// context

import context from "../../store/context";

// components
import Input from "../Input";
import AuthenticationButton from "../Buttons/AuthenticationButton";

// hooks
import useInput from "../../hooks/use-Input";

// icons
import PersonIcon from "../icons/Person";
import LockIcon from "../icons/Lock";
import ErrorIcon from "../icons/Error";
import API_ENDPOINT from "../../config";

const LoginForm = (props) => {
  const emailorPhoneValidator = (enteredValue) => {
    // Regular expression for email or phone number validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^(09|\+251|07|\+2517)\d{8}$|^(\+251)\d{9}$/;

    return emailRegex.test(enteredValue) || phoneRegex.test(enteredValue);
  };
  const passwordValidator = (enteredValue) => {
    return enteredValue.trim().length >= 6;
  };
  const {
    enteredValue: emailValue,
    hasError: emailHasError,
    valueChangeHandler: emailValueChangeHandler,
    inputBlurHandler: emailInputBlurHandler,
    isTouched: emailIsTouched,
    isValid: emailIsValid,
    reset: emailReset,
  } = useInput(emailorPhoneValidator);

  const {
    enteredValue: passwordValue,
    hasError: passwordHasError,
    valueChangeHandler: passwordValueChangeHandler,
    inputBlurHandler: passwordInputBlurHandler,
    isTouched: passwordIsTouched,
    isValid: passwordIsValid,
    reset: passwordReset,
  } = useInput(passwordValidator);

  // fetch

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const transformedPhoneValue = emailValue.startsWith("+251")
    ? "0" + emailValue.substring(4)
    : emailValue;

  var raw = JSON.stringify({
    username: transformedPhoneValue,
    password: passwordValue,
  });

  const navigate = useNavigate();

  const [isSending, setIsSending] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { setToken } = useContext(context);
  const sumbitUserInfo = () => {
    if (emailIsValid && passwordIsValid) {
      setIsSending(true);
      setHasError(false);
      // validate phone
      fetch(`${API_ENDPOINT}/auth/login`, {
        method: "POST",
        headers: myHeaders,
        body: raw,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            localStorage.setItem("token", data.token); // Save token to localStorage
            setToken(data.token);
            navigate("/tenders");
            // props.onClick();
          } else {
            setHasError(true);
          }
          setIsSending(false);
        })
        .catch((err) => {
          setIsSending(false);
          setHasError(true);
          console.log(err);
        });
    }
  };

  return (
    <div className="w-full flex flex-col space-y-3 md:space-y-5">
      <div className="flex flex-col text-center text-[#664bc7] space-y-1 sm:space-y-2.5 ">
        <span className="font-semibold sm:text-lg">ሰላም 👋 , Welcome Back!</span>
        <span className="font-semibold text-3xl md:text-[35px]">
          Login to iChereta
        </span>
      </div>
      <div className="py-6">
        <div className="w-full h-10  rounded ">
          {hasError && (
            <div className="flex items-center h-full bg-red-700/5 w-full">
              <ErrorIcon styles="w-6 h-6 text-red-400 mx-3" />
              <span className="text-red-400 text-sm ">Invalid Credential</span>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col space-y-3">
        <Input
          onChange={emailValueChangeHandler}
          onBlur={emailInputBlurHandler}
          hasError={emailHasError}
          isValid={emailIsValid}
          isTouched={emailIsTouched}
          enteredValue={emailValue}
          reset={emailReset}
          type="text"
          id="emailorphone"
          lable="Email or Phone Number"
        >
          <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>

        <Input
          onChange={passwordValueChangeHandler}
          onBlur={passwordInputBlurHandler}
          hasError={passwordHasError}
          isValid={passwordIsValid}
          isTouched={passwordIsTouched}
          enteredValue={passwordValue}
          reset={passwordReset}
          type="password"
          id="loginPassword"
          lable="Password"
        >
          <LockIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="space-y-3 sm:space-y-4 md:space-y-5">
        <Link to="/forgot-password">
          <button className="text-[#664bc7] text-sm sm:text-base font-medium">
            Forgot Password ?
          </button>
        </Link>

        <AuthenticationButton
          process={isSending}
          name="Log In"
          onClick={sumbitUserInfo}
        />
      </div>
    </div>
  );
};

export default LoginForm;
