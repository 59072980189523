const Tos = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="w-full max-w-[1000px] sm:bg-slate-100 flex flex-col flex-grow space-y-8 p-4 sm:p-7 md:p-10 my-12 rounded-xl">
        <span className="text-2xl font-bold">Terms of Services</span>

        <ol className="list-decimal space-y-8 p-5">
          <li>
            <div className="flex flex-col space-y-2">
              <span className="font-bold text-lg">DEFINITIONS</span>
              <ul className="list-disc px-5">
                <li>HahuCloud Technologies PLC referred to as "the Company"</li>
                <li>
                  iChereta, the subscription-based Tender Alerting Service
                  through email, phone, sms, and telegram, referred to as "the
                  Service"
                </li>
                <li>
                  The users of the Service are referred to as "the Subscriber"
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div className="flex flex-col space-y-2">
              <span className="font-bold text-lg">SCOPE AND INTENT</span>
              <span>
                These terms of service ("TOS") govern the use of the Service
                provided by the Company. By subscribing to the Service, the
                Subscriber agrees to be bound by these TOS.
              </span>
            </div>
          </li>
          <li>
            <div className="flex flex-col space-y-2">
              <span className="font-bold text-lg">PROVISION OF SERVICES</span>
              <span>
                The Company will provide the Service to the Subscriber for a
                maximum of one-year subscription, starting from the date of
                purchase. The Service includes Tender Alerting Service through
                email, phone, sms, and telegram.
              </span>
            </div>
          </li>
          <li>
            <div className="flex flex-col space-y-2">
              <span className="font-bold text-lg">ACCESS TO SERVICES</span>
              <span>
                The Subscriber will be provided with access to the Service after
                the successful completion of the purchase. The Subscriber will
                be required to provide accurate and complete information during
                the registration process.
              </span>
            </div>
          </li>
          <li>
            <div className="flex flex-col space-y-2">
              <span className="font-bold text-lg">USAGE RESTRICTIONS</span>
              <span>
                The Subscriber agrees to use the Service only for lawful
                purposes and in compliance with all applicable laws and
                regulations. The Subscriber shall not use the Service to
                transmit any content that is unlawful, harmful, threatening,
                abusive, harassing, defamatory, vulgar, obscene, libelous,
                invasive of another's privacy, hateful, or racially, ethnically
                or otherwise objectionable.
              </span>
            </div>
          </li>
          <li>
            <div className="flex flex-col space-y-2">
              <span className="font-bold text-lg">
                FEES AND PAYMENT FOR PURCHASED SERVICES
              </span>
              <span>
                The Subscriber shall pay the Company the fees for the Service as
                set forth on the iChereta website. The Subscriber shall make
                payment in advance for the subscription period selected. The
                Company's refund policy is available on the refund policy page.
              </span>
            </div>
          </li>
          <li>
            <div className="flex flex-col space-y-2">
              <span className="font-bold text-lg">
                DISCLAIMER OF WARRANTIES
              </span>
              <span>
                The Company makes no warranties, whether express or implied,
                regarding the Service. The Service is provided "as is" and the
                Company disclaims all warranties, including but not limited to
                warranties of merchantability, fitness for a particular purpose,
                and non-infringement.
              </span>
            </div>
          </li>
          <li>
            <div className="flex flex-col space-y-2">
              <span className="font-bold text-lg">LIMITATION OF LIABILITY</span>
              <span>
                The Company shall not be liable for any indirect, special,
                incidental, or consequential damages arising out of or in
                connection with the Service, including but not limited to
                damages for loss of profits, loss of data, or any other
                commercial damages or losses.
              </span>
            </div>
          </li>
          <li>
            <div className="flex flex-col space-y-2">
              <span className="font-bold text-lg">TERM AND TERMINATION</span>
              <span>
                These TOS shall remain in effect until terminated by either
                party. The Company may terminate these TOS immediately upon
                notice to the Subscriber if the Subscriber breaches any
                provision of these TOS.
              </span>
            </div>
          </li>
          <li>
            <div className="flex flex-col space-y-2">
              <span className="font-bold text-lg">
                GOVERNING LAW (ETHIOPIA)
              </span>
              <span>
                These TOS shall be governed by and construed in accordance with
                the laws of Ethiopia, without giving effect to any principles of
                conflicts of law.
              </span>
            </div>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Tos;
