import { useState, useContext, useEffect } from "react";
import context from "../../store/context";

import useInput from "../../hooks/use-Input";

import Input from "../Input";
import AuthenticationButton from "../Buttons/AuthenticationButton";

//icons
import BuildingIcon from "../icons/Building";
import TinIcon from "../icons/Tin";
import LocationIcon from "../icons/Location";
import API_ENDPOINT from "../../config";

const CompanyInfo = () => {
  const { userInfo, setUserInfo } = useContext(context);

  const validateCompanyInfo = () => {
    return true;
  };

  const {
    enteredValue: companyNameValue,
    hasError: companyNameHasError,
    valueChangeHandler: companyNameValueChangeHandler,
    inputBlurHandler: companyNameInputBlurHandler,
    isTouched: companyNameIsTouched,
    isValid: companyNameIsValid,
    reset: companyNameReset,
  } = useInput(
    validateCompanyInfo,
    userInfo.company_name === null ? "" : userInfo.company_name
  );

  const {
    enteredValue: tinValue,
    hasError: tinHasError,
    valueChangeHandler: tinChangeHandler,
    inputBlurHandler: tinInputBlurHandler,
    isTouched: tinIsTouched,
    isValid: tinIsValid,
    reset: tinReset,
  } = useInput(validateCompanyInfo, userInfo.tin === null ? "" : userInfo.tin);

  const {
    enteredValue: addressValue,
    hasError: addressHasError,
    valueChangeHandler: addressChangeHandler,
    inputBlurHandler: addressInputBlurHandler,
    isTouched: addressIsTouched,
    isValid: addressIsValid,
    reset: addressReset,
  } = useInput(
    validateCompanyInfo,
    userInfo.address === null ? "" : userInfo.address
  );

  useEffect(() => {
    companyNameReset();
    tinReset();
    addressReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const userToken = localStorage.getItem("token");
  const [isUpdating, setIsUpdating] = useState(false);

  const updatePersonalInfo = () => {
    setIsUpdating(true);
    var headers = new Headers();
    headers.append("X-XSRF-TOKEN", "csrf-cookie");
    headers.append("Accept", "application/json");
    headers.append("Authorization", `Bearer ${userToken}`);

    var requestOptions = {
      method: "POST",
      headers: headers,
      redirect: "follow",
    };

    fetch(
      `${API_ENDPOINT}/client/account/company?company_name=${companyNameValue}&tin=${tinValue}&address=${addressValue}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        return response.json();
      })
      .then((result) => {
        setIsUpdating(false);

        setUserInfo(result);
      })
      .catch((error) => {
        setIsUpdating(false);
        console.log("error", error);
      });
  };
  return (
    <div className="flex flex-col">
      <div className="border-b pb-2 mb-4">
        <h1 className="text-lg font-medium">Company Information</h1>
      </div>
      <div className="flex flex-col space-y-2">
        <h1 className="text-sm">Company Name</h1>
        <Input
          onChange={companyNameValueChangeHandler}
          onBlur={companyNameInputBlurHandler}
          hasError={companyNameHasError}
          isValid={companyNameIsValid}
          isTouched={companyNameIsTouched}
          enteredValue={companyNameValue}
          reset={companyNameReset}
          type="text"
          id="companyInfoName"
        >
          <BuildingIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="flex flex-col space-y-2">
        <h1 className="text-sm">TIN</h1>
        <Input
          onChange={tinChangeHandler}
          onBlur={tinInputBlurHandler}
          hasError={tinHasError}
          isValid={tinIsValid}
          isTouched={tinIsTouched}
          enteredValue={tinValue}
          reset={tinReset}
          type="number"
          id="companyInfoTIN"
        >
          <TinIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="flex flex-col space-y-2">
        <h1 className="text-sm">Address</h1>
        <Input
          onChange={addressChangeHandler}
          onBlur={addressInputBlurHandler}
          hasError={addressHasError}
          isValid={addressIsValid}
          isTouched={addressIsTouched}
          enteredValue={addressValue}
          reset={addressReset}
          type="text"
          id="companyInfoAddress"
        >
          <LocationIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="pt-5">
        <AuthenticationButton
          name="Save Changes"
          process={isUpdating}
          onClick={updatePersonalInfo}
        />
      </div>
    </div>
  );
};

export default CompanyInfo;
