import { useEffect, useContext } from "react";
import context from "../store/context";

import TendersList from "../pages/Tender/TendersList";
import NoPreferance from "../components/cards/NoPreferance";

const MyFeedTelegram = () => {
  const { setTelegramApiPath } = useContext(context);

  useEffect(() => {
    setTelegramApiPath("myfeed");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.Telegram.WebApp.BackButton.hide();

  return (
    <TendersList
      noTendersPlaceholder={<NoPreferance />}
      categorySlider={false}
      showSearchSidebar={false}
    />
  );
};

export default MyFeedTelegram;
