import ArrowIcon from "../icons/Arrow";

const Carousel = (props) => {
  const leftScrollHandler = () => {
    scrollhandler("left", 5, 270, 10);
  };

  const rightScrollHandler = () => {
    scrollhandler("right", 5, 270, 10);
  };
  const scrollhandler = (direction, speed, distance, step) => {
    var element = document.getElementById("containerSlide");
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }

      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  return (
    <div className="w-[99%] lg:w-[96%] flex justify-evenly max-w-[1588px] space-x-1 sm:space-x-2 md:space-x-3">
      {props.arrows && (
        <button onClick={leftScrollHandler}>
          <ArrowIcon
            styles={
              "w-5 h-5 sm:w-6 sm:h-6 md:w-8 md:h-8 text-[#664bc7] rotate-180"
            }
          />
        </button>
      )}

      <div
        id="containerSlide"
        className="w-full h-full overflow-x-scroll flex scroll"
      >
        <div className="flex justify-center  space-x-5 py-5">
          {props.children}
        </div>
      </div>
      {props.arrows && (
        <button onClick={rightScrollHandler}>
          <ArrowIcon
            styles={"w-5 h-5 sm:w-6 sm:h-6 md:w-8 md:h-8 text-[#664bc7]"}
          />
        </button>
      )}
    </div>
  );
};

export default Carousel;
