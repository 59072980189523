const AdBanner = ({
  latestPlan,
  invoiceAmount,
  HandleSubscription,
  isFetchingInvoice,
}) => {
  return (
    <div class="flex justify-between items-center w-full bg-white p-4 border-2 border-yellow-400 rounded-lg">
      <div class="text-left w-[60%] border-r border-[#607d8b36] pr-2 flex flex-col gap-2">
        <h2 class="text-md font-bold text-orange-500">የአባልነት ክፍያ ይፈፅሙ።</h2>
        <p class="font-bold text-xs text-[#3d5984]">
          የጨረታዎችን ሙሉ ዝርዝር መረጃ ያለምንም ገደብ ለማግኘት የአባልነት ክፍያ ይፈፅሙ።
        </p>
      </div>
      <div class=" w-[38%]flex text-[#3d5984] flex-col items-center  text-right">
        <div class="mb-2 text-sm">
          <span class="font-semibold text-xs">ጥቅል: {latestPlan}</span>
          <br />
          <span class="font-semibold text-xs">ክፍያ: {invoiceAmount} ETB</span>
        </div>

        <div>
          <button
            onClick={HandleSubscription}
            className={`py-1.5 animate-button text-white shadow w-[100%] rounded relative        text-center mx-auto text-lg font-bold px-4`}
          >
            <span className="text-semibold text-sm">ይክፈሉ!</span>
            {isFetchingInvoice && (
              <div className="absolute top-0 left-0 w-full h-full rounded bg-black/40 flex items-center justify-center">
                <div className=" animate-spin border-t border-white w-5 h-5 rounded-full"></div>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default AdBanner;
