import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import "../../shimmer.css";

import DateConverter from "../../components/DateConverter";
import API_ENDPOINT from "../../config";

const BillingPage = () => {
  const userToken = localStorage.getItem("token");

  const [data, setData] = useState();

  const getBills = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_ENDPOINT}/client/invoice`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getBills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full flex justify-center bg-[#f8f8ff] sm:bg-white ">
      <div className="relative w-[95%] sm:w-[90%] max-w-[1500px] sm:bg-[#f8f8ff]  overflow-y-auto h-[calc(100vh-61px)] sm:h-[calc(100vh-73px)] md:h-[calc(100vh-69px)] lg:h-[calc(100vh-79px)] py-5 md:py-10">
        <div className="w-full flex justify-center text-[#3d5984]">
          <div className="flex w-full flex-col space-y-5 md:space-y-10 items-center">
            <div className="w-full max-w-[600px]  rounded-md bg-white text-center px-5 py-5 md:py-8 space-y-2 md:space-y-5">
              <h1 className="text-lg md:text-xl font-bold">SUBSCRIPTION</h1>

              <div className="flex w-full justify-center space-x-2">
                {data ? <h1> {data.latest_plan}</h1> : <span>...</span>}
                {data ? (
                  Number(data.latest_plan_status) !== 1 ? (
                    <h1 className="text-red-500">- Inactive Subscription </h1>
                  ) : (
                    <h1 className="text-green-500">- Active Subscription</h1>
                  )
                ) : (
                  ""
                )}
              </div>

              {/* <p className="text-xs sm:text-sm md:text-base">Expire Date:</p> */}
            </div>
            <div className="w-[200px] bg-white p-1 rounded-full border border-[#3d5984]/50">
              <div className="flex items-center relative w-full rounded-full overflow-hidden z-10 text-xs sm:text-sm">
                <div
                  className={`px-4 py-2  w-full  bg-[#3d5984] text-white rounded-full transition-colors duration-200 text-center`}
                >
                  INVOICES
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full max-w-[800px] overflow-x-auto p-5  bg-white rounded-md">
              <table className="w-full border-collapse min-w-max">
                <thead className="bg-slate-100">
                  <tr>
                    <th className="px-4 py-2">Invoice ID</th>
                    <th className=" px-4 py-2">Method</th>
                    <th className=" px-4 py-2">Invoice Date</th>
                    <th className=" px-4 py-2">Amount</th>
                    <th className=" px-4 py-2">Status</th>
                    <th
                      className={
                        data && data.invoices.length !== 0
                          ? ""
                          : `w-[120px] py-2`
                      }
                    ></th>
                  </tr>
                </thead>

                <tbody className="divide-y divide-slate-50">
                  {data &&
                    data.invoices.length !== 0 &&
                    data.invoices.map((invoice) => (
                      <tr
                        key={invoice.id}
                        className="hover:bg-slate-50/50 text-center"
                      >
                        <td className=" px-4 py-2">
                          #{invoice.invoice_number}
                        </td>
                        <td className="px-4 py-2 ">
                          {invoice.payment_method_id}
                        </td>
                        <td className=" px-4 py-2">
                          <DateConverter dateString={invoice.created_at} />
                        </td>
                        <td className=" px-4 py-2">{invoice.amount}</td>
                        <td className=" px-4 py-2">{invoice.status}</td>
                        {/* <td className=" py-2">
                          <Link
                            to={`/invoice/${invoice.id}`}
                            className="w-[100px] py-1 bg-[#3d5984] hover:bg-[#3d5984]/90 transition-all duration-200 text-white rounded-full text-sm text-center"
                          >
                            <button className="w-[100px]">view</button>
                          </Link>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
              {!data && (
                <div className="w-full h-9 animate-pulse-gradient mt-1 rounded-md"></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingPage;
