import "../../shimmer.css";

const AuthenticationButton = (props) => {
  return (
    <button
      disabled={props.process} // Add the disabled attribute
      onClick={props.onClick}
      className="w-full h-12 md:h-[52px] bg-[#664bc7] hover:bg-[#664bc7]/90 text-white transition-colors duration-200 text-base md:text-lg rounded relative animate-action-button"
    >
      {props.process && (
        <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center bg-black/50 ">
          <div className="border-t border-white w-9 h-9 rounded-full animate-spin"></div>
        </div>
      )}
      {props.name}
    </button>
  );
};

export default AuthenticationButton;
