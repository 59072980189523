const FacebookIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.styles}
      viewBox="0 0 8.503 15.805"
    >
      <path
        id="Icon_zocial-facebook"
        dataname="Icon zocial-facebook"
        d="M13.257,8.487V5.469h2.434V3.951a3.972,3.972,0,0,1,1.066-2.8A3.392,3.392,0,0,1,19.342,0H21.76V3.019H19.342a.533.533,0,0,0-.426.261,1.059,1.059,0,0,0-.19.64V5.468H21.76V8.487H18.725V15.8H15.691V8.487Z"
        transform="translate(-13.257)"
        fill="#523ca2"
      ></path>
    </svg>
  );
};

export default FacebookIcon;
