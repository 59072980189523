import { useState, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import context from "../../store/context";

import teleBirrLogo from "../../assets/telebirrlogo.svg";
import amoleLogo from "../../assets/amolelogo.png";
import cbeBirrLogo from "../../assets/cbebirr.png";

import LockIcon from "../icons/Lock";
import zigzagArrow from "../../assets/trend.png";

import "../../shimmer.css";

const TenderContentPlaceholder = ({ snippet }) => {
  const userToken = localStorage.getItem("token");
  const { HandleSubscription, isFetchingInvoice, userInfo } =
    useContext(context);
  const navigate = useNavigate();
  const navigateToPayment = () => {
    navigate("/signup");
  };

  const location = useLocation();

  const telegramPage = location.pathname.startsWith("/tender/telegram");

  const [telegramIsPaying, setTelegramIsPaying] = useState(false);

  const telegramPay = () => {
    setTelegramIsPaying(true);
    const auth_data = encodeURIComponent(window.Telegram.WebApp.initData);

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      "/telegram/get-invoice-url?data_check_string=" + auth_data,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        setTelegramIsPaying(false);

        if (response.ok) {
          window.Telegram.WebApp.openInvoice(response.result, (res) => {
            if (res === "paid") {
              window.location.reload();
              window.Telegram.WebApp.showPopup({
                title: "Paid!",
                message: "Your payment was successful.",
                buttons: [{ type: "close", text: "Close" }],
              });
              window.Telegram.WebApp.HapticFeedback.notificationOccurred(
                "success"
              );
            } else if (res === "cancelled") {
              window.Telegram.WebApp.showPopup({
                title: "Not Paid!",
                message: "You have aborted the payment process",
                buttons: [{ type: "close", text: "Close" }],
              });
              window.Telegram.WebApp.HapticFeedback.notificationOccurred(
                "error"
              );
            }
          });
        }
      })
      .catch((error) => console.log("error", error));
    // window.Telegram.WebApp.openTelegramLink(
    //   "https://t.me/iCheretaBot?start=pay_now"
    // );
  };

  return (
    <div className="flex flex-col space-y-3 w-screen overflow-hidden">
      <div className="relative">
        <div className="absolute w-full h-full bg-gradient-to-b from-transparent  to-white"></div>
        <h1 className="font-bold">{snippet}...</h1>
      </div>
      <div className="w-full h-[350px] relative overflow-hidden break-words">
        <div className="space-y-4 break-words">
          <h1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </h1>
          <h1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </h1>
          <h1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </h1>
          <h1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </h1>
          <h1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </h1>
          <h1>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </h1>
        </div>
        <div className="w-full h-[350px] backdrop-blur-sm bg-white/10 rounded flex items-start justify-center text-[#664bc7] absolute top-0 left-0">
          {telegramPage ? (
            <div className="flex mx-3 w-[97%] max-w-[400px] flex-col items-center space-y-5  px-10 py-6 bg-white/90 rounded-md shadow mt-3 border-l-8 border-y border-r border-yellow-600">
              <div className="flex flex-col space-y-3 text-center">
                <h1 className="text-yellow-500 font-bold text-lg">
                  የአባልነት ክፍያ ይፈፅሙ ።
                </h1>
                <p className="text-gray-500 text-xs">
                  የዚህን ጨረታ እንዲሁም የሌሎችን ጨረታዎች ሙሉ መረጃ ለማግኘት
                  <br /> አባል መሆን ይኖርቦታል።
                </p>
              </div>

              <button
                onClick={telegramPay}
                disabled={telegramIsPaying}
                className="w-full py-1.5 text-xl font-bold text-white animate-button relative overflow-hidden"
              >
                ይክፈሉ!
                {telegramIsPaying && (
                  <div className="w-full h-full absolute top-0 left-0 flex justify-center items-center">
                    <div className="w-6 h-6 rounded-full border-t border-white animate-spin"></div>
                  </div>
                )}
              </button>
              <h1>{userInfo?.latest_plan}</h1>
              <h1>{userInfo?.amount}</h1>
              {/* <hr className="border-dashed border border-slate-400" />
              <div className="w-full flex justify-between text-gray-600">
                <div className="flex flex-col items-start space-y-1">
                  <h1>የአባልነት ጥቅል</h1>
                  <h1>{userInfo?.latest_plan}</h1>
                </div>
                <div className="flex flex-col items-end space-y-1">
                  <h1>የክፍያ መጠን</h1>
                  <h1>{userInfo?.amount}</h1>
                </div>
              </div> */}
            </div>
          ) : (
            <div className="flex mx-5 w-[97%] max-w-[400px] flex-col items-center space-y-5  px-10 py-5 bg-white/90 rounded-md shadow mt-3">
              <LockIcon styles="w-8 h-8" />
              <div className="text-center space-y-2">
                {userToken ? (
                  <h1 className="text-sm sm:text-lg text-gray-600 font-bold">
                    የiChereta አካውንቶ አክቲቭ አይደለም ፣ ጨረታዎችን <br /> ለመመልከት ክፍያ ይፍጽሙ!
                  </h1>
                ) : (
                  <h1 className="text-sm sm:text-lg font-bold text-gray-600 break-words">
                    የዚህን ጨረታ እንዲሁም የሌሎችን ጨረታዎች <br /> ሙሉ መረጃ ለማግኘት የiChereta
                    <br /> አባል መሆን ይኖርቦታል።
                  </h1>
                )}
              </div>

              {userToken && (
                <div className="flex items-center space-x-3">
                  <img
                    src={teleBirrLogo}
                    alt="Telebirr logo"
                    className="w-12 sm:w-16"
                  />
                  <img src={cbeBirrLogo} alt="CBE Birr logo" className="w-12" />
                  <img
                    src={amoleLogo}
                    alt="Amole Logo"
                    className="w-12 sm:w-16"
                  />
                </div>
              )}

              <div className="flex flex-col justify-center  space-x-5 relative w-full">
                <div className="flex items-center space-x-4 justify-center">
                  {!userToken && (
                    <Link
                      to="/login"
                      className="text-[#664bc7] hover:bg-blue-50/50 bg-white shadow w-[150px] py-2 rounded text-center text-lg font-bold border border-[#664bc7]/10"
                    >
                      ይግቡ
                    </Link>
                  )}

                  <div className="flex flex-col items-center w-full">
                    {userToken && (
                      <div className="animate-bounce">
                        <img
                          className="w-8 rotate-[120deg] "
                          src={zigzagArrow}
                          alt="a zigzag arrow"
                        />
                      </div>
                    )}
                    <button
                      onClick={() => {
                        HandleSubscription(navigateToPayment);
                      }}
                      className={`${
                        userToken
                          ? "py-1.5 animate-button"
                          : "bg-[#664bc7] hover:bg-[#664bc7]/90 py-2 w-full"
                      }  text-white shadow  w-[150px] rounded relative text-center mx-auto text-lg font-bold `}
                    >
                      {userToken ? (
                        <span className="text-semibold">ይክፈሉ!</span>
                      ) : (
                        <span>ይመዝገቡ</span>
                      )}
                      {isFetchingInvoice && (
                        <div className="absolute top-0 left-0 w-full h-full rounded bg-black/40 flex items-center justify-center">
                          <div className=" animate-spin border-t border-white w-5 h-5 rounded-full"></div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
                {!userToken && (
                  <div className=" top-full flex flex-col items-center right-0 text-center text-[#664bc7]">
                    <img
                      className="w-8 -rotate-90"
                      src={zigzagArrow}
                      alt="a zigzag arrow"
                    />
                    <p className="min-w-max text-gray-600 font-bold">
                      ከ <span className="text-green-500">980</span>
                      ብር ጀምሮ በመክፈል አባል ሲሆኑ ሁሉንም <br /> የጨረታ መረጃዎች ያለገደብ ማግኘት
                      ይችላሉ።
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TenderContentPlaceholder;
