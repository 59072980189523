import React from "react";

import CheckIcon from "../icons/Check";

const RadioButtons = (props) => {
  return (
    <>
      <label
        className={`h-[60px]  flex flex-col justify-between items-center border ${
          props.styles
        } rounded p-2 relative transition-colors duration-200 ${
          props.active === props.id
            ? "bg-[#664bc7] text-white"
            : "bg-white text-gray-500"
        }`}
      >
        <input
          id={props.id}
          type="radio"
          name="types"
          value={props.duration}
          onChange={props.onChange}
          checked={props.active === props.duration}
          className="hidden"
        />

        {props.active === props.id && (
          <CheckIcon styles="w-4 h-4  absolute text-white  top-1 left-1" />
        )}

        <div className="flex flex-col items-center justify-center flex-grow space-y-1 sm:space-y-2 md:space-y-3">
          <span className="text-sm ">{props.name}</span>
        </div>
      </label>
    </>
  );
};

export default RadioButtons;
