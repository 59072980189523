import CheckIcon from "./icons/Check";

const PriceSelector = (props) => {
  return (
    <>
      <label
        className={`h-[93.5px] sm:h-[108px]  flex flex-col justify-between items-center border border-gray-300 rounded p-2 relative transition-colors duration-200 ${
          props.active === props.id
            ? "bg-[#664bc7] text-white"
            : "bg-white text-gray-700"
        }`}
      >
        <input
          id={props.id}
          type="radio"
          name="duration"
          value={props.duration}
          onChange={props.onChange}
          checked={props.active === props.duration}
          className="hidden"
        />

        {props.active === props.id ? (
          <CheckIcon styles="w-4 h-4 sm:w-5 sm:h-5 absolute text-white  top-1 left-1 sm:top-2 sm:left-2" />
        ) : (
          <div className="border border-gray-400 rounded-full w-3 h-3 sm:w-4 sm:h-4 absolute text-red-500  top-1 left-1 sm:top-2 sm:left-2"></div>
        )}

        <div className="flex flex-col items-center justify-center flex-grow space-y-1 sm:space-y-2 md:space-y-3">
          <span className="text-sm sm:text-base ">{props.duration}</span>
          <h1 className="text-lg sm:text-xl  font-medium">{props.price}</h1>
        </div>
      </label>
    </>
  );
};

export default PriceSelector;
