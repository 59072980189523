import { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import context from "../store/context";

import Input from "../components/Input";
import useInput from "../hooks/use-Input";

import PersonIcon from "../components/icons/Person";

import logo from "../assets/logowitht.svg";
import pdfIcon from "../assets/pdf.svg";
import API_ENDPOINT from "../config";

const PurchaseBidDoc = () => {
  const { userInfo } = useContext(context);

  const params = useParams();

  // Function to return the first name
  function getFirstName(str) {
    return str.split(" ")[0] || "";
  }

  // Function to return the last name
  function getLastName(str) {
    var splitString = str.split(" ");
    if (splitString.length > 1) {
      return splitString.slice(1).join(" ");
    } else {
      return "";
    }
  }

  const validateCompanyInfo = () => {
    return true;
  };

  const {
    enteredValue: firstNameValue,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameValueChangeHandler,
    inputBlurHandler: firstNameInputBlurHandler,
    isTouched: firstNameIsTouched,
    isValid: firstNameIsValid,
    reset: firstNameReset,
  } = useInput(validateCompanyInfo, getFirstName(userInfo?.name) || "");

  const {
    enteredValue: lastNameValue,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameValueChangeHandler,
    inputBlurHandler: lastNameInputBlurHandler,
    isTouched: lastNameIsTouched,
    isValid: lastNameIsValid,
    reset: lastNameReset,
  } = useInput(validateCompanyInfo, getLastName(userInfo?.name) || "");

  const {
    enteredValue: phoneNumValue,
    hasError: phoneNumHasError,
    valueChangeHandler: phoneNumValueChangeHandler,
    inputBlurHandler: phoneNumInputBlurHandler,
    isTouched: phoneNumIsTouched,
    isValid: phoneNumIsValid,
    reset: phoneNumReset,
  } = useInput(validateCompanyInfo, userInfo?.phone || "");

  const {
    enteredValue: emailValue,
    hasError: emailHasError,
    valueChangeHandler: emailValueChangeHandler,
    inputBlurHandler: emailInputBlurHandler,
    isTouched: emailIsTouched,
    isValid: emailIsValid,
    reset: emailReset,
  } = useInput(validateCompanyInfo, userInfo?.email || "");

  const {
    enteredValue: companyNameValue,
    hasError: companyNameHasError,
    valueChangeHandler: companyNameValueChangeHandler,
    inputBlurHandler: companyNameInputBlurHandler,
    isTouched: companyNameIsTouched,
    isValid: companyNameIsValid,
    reset: companyNameReset,
  } = useInput(validateCompanyInfo, userInfo.company_name || "");

  const {
    enteredValue: addressValue,
    hasError: addressHasError,
    valueChangeHandler: addressValueChangeHandler,
    inputBlurHandler: addressInputBlurHandler,
    isTouched: addressIsTouched,
    isValid: addressIsValid,
    reset: addressReset,
  } = useInput(validateCompanyInfo, userInfo?.address || "");

  const {
    enteredValue: tinValue,
    hasError: tinHasError,
    valueChangeHandler: tinValueChangeHandler,
    inputBlurHandler: tinInputBlurHandler,
    isTouched: tinIsTouched,
    isValid: tinIsValid,
    reset: tinReset,
  } = useInput(validateCompanyInfo, userInfo?.tin || "");
  const {
    enteredValue: vatValue,
    hasError: vatHasError,
    valueChangeHandler: vatValueChangeHandler,
    inputBlurHandler: vatInputBlurHandler,
    isTouched: vatIsTouched,
    isValid: vatIsValid,
    reset: vatReset,
  } = useInput(validateCompanyInfo, "");

  const [refresh, setRefresh] = useState(true);
  const [docDetail, setDocDetail] = useState({
    id: "",
    title: "",
    description: "",
    price: "",
    file_size: "",
    is_purchased: "",
    company: {
      id: "",
      name: "",
    },
  });

  const submitForm = () => {
    console.log({
      firstName: firstNameValue,
      lastName: lastNameValue,
      phone: phoneNumValue,
      email: emailValue,
      company_name: companyNameValue,
      address: addressValue,
      tin: tinValue,
      vat: vatValue,
    });

    const token = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${API_ENDPOINT}/document/1/order?first_name=${firstNameValue}&last_name=${lastNameValue}&address=${addressValue}&email=${emailValue}&phone=${phoneNumValue}&TIN=${tinValue}&VAT_number=${vatValue}&price=${docDetail?.price}&company_name=${companyNameValue}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result?.url);
        window.location.replace(result?.url);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    setRefresh(!refresh);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const getDocDetail = () => {
    const token = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_ENDPOINT}/document/${params.docId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDocDetail(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getDocDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen flex flex-col items-center mb-[30px]">
      <div className="w-full h-[500px] bg-[#ebedfc]"></div>
      <div className=" w-[95%] max-w-[800px]  -mt-[500px] flex flex-col items-center ">
        <div className="py-[30px] flex flex-col items-center gap-5">
          <div className="w-[200px] ">
            <img src={logo} alt="ichereta logo" />
          </div>
          <div className="flex flex-col items-center gap-2">
            <p className="text-3xl font-semibold text-slate-700">
              Purchase Bid Document.
            </p>
            <p className="text-sm text-center">
              PLEASE FILL OUT THE REQUIRED INFORMATION IN ORDER TO COMPLETE THE
              PURCHASE.
            </p>
          </div>
        </div>
        <div className="border border-slate-200 rounded-lg px-[15px] sm:px-[50px] py-[30px] bg-white w-full">
          <div className="mb-[50px] flex flex-col gap-2">
            <p className="font-[500]">Document</p>
            <div className="w-full sm:h-[150px] p-[10px] sm:p-[50px] border border-slate-300 bg-slate-100 rounded-lg flex flex-col sm:flex-row gap-[30px] items-center">
              <img src={pdfIcon} alt="PDF" className="w-[70px]" />

              <div className="h-[120px] w-[1px] bg-slate-300 hidden sm:block"></div>
              <div>
                <p className="font-bold">{docDetail?.title}</p>
                <p className="text-sm">By: {docDetail?.company?.name}</p>
                <p className="text-sm">File Size : {docDetail?.file_size}</p>
                <p className="text-sm">Price: {docDetail?.price} Birr</p>
              </div>
            </div>
          </div>

          {/*  inputs start */}
          <div className="flex flex-col sm:flex-row sm:gap-5">
            <div className="flex flex-col gap-2 w-full sm:w-1/2">
              <label>First Name</label>
              <Input
                onChange={firstNameValueChangeHandler}
                onBlur={firstNameInputBlurHandler}
                hasError={firstNameHasError}
                isValid={firstNameIsValid}
                isTouched={firstNameIsTouched}
                enteredValue={firstNameValue}
                reset={firstNameReset}
                type="text"
                id="companyInfoName"
              >
                <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
              </Input>
            </div>{" "}
            <div className="flex flex-col gap-2 w-full sm:w-1/2">
              <label>Last Name</label>
              <Input
                onChange={lastNameValueChangeHandler}
                onBlur={lastNameInputBlurHandler}
                hasError={lastNameHasError}
                isValid={lastNameIsValid}
                isTouched={lastNameIsTouched}
                enteredValue={lastNameValue}
                reset={lastNameReset}
                type="text"
                id="companyInfoName"
              >
                <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
              </Input>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row sm:gap-5">
            <div className="flex flex-col gap-2 w-full sm:w-1/2">
              <label>Phone Number</label>
              <Input
                onChange={phoneNumValueChangeHandler}
                onBlur={phoneNumInputBlurHandler}
                hasError={phoneNumHasError}
                isValid={phoneNumIsValid}
                isTouched={phoneNumIsTouched}
                enteredValue={phoneNumValue}
                reset={phoneNumReset}
                type="text"
                id="companyInfoName"
              >
                <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
              </Input>
            </div>{" "}
            <div className="flex flex-col gap-2 w-full sm:w-1/2">
              <label>Email</label>
              <Input
                onChange={emailValueChangeHandler}
                onBlur={emailInputBlurHandler}
                hasError={emailHasError}
                isValid={emailIsValid}
                isTouched={emailIsTouched}
                enteredValue={emailValue}
                reset={emailReset}
                type="text"
                id="companyInfoName"
              >
                <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
              </Input>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row sm:gap-5">
            <div className="flex flex-col gap-2 w-full sm:w-1/2">
              <label>Company Name</label>
              <Input
                onChange={companyNameValueChangeHandler}
                onBlur={companyNameInputBlurHandler}
                hasError={companyNameHasError}
                isValid={companyNameIsValid}
                isTouched={companyNameIsTouched}
                enteredValue={companyNameValue}
                reset={companyNameReset}
                type="text"
                id="companyInfoName"
              >
                <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
              </Input>
            </div>
            <div className="flex flex-col gap-2 w-full sm:w-1/2">
              <label>Address</label>
              <Input
                onChange={addressValueChangeHandler}
                onBlur={addressInputBlurHandler}
                hasError={addressHasError}
                isValid={addressIsValid}
                isTouched={addressIsTouched}
                enteredValue={addressValue}
                reset={addressReset}
                type="text"
                id="companyInfoName"
              >
                <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
              </Input>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row sm:gap-5">
            <div className="flex flex-col gap-2 w-full sm:w-1/2">
              <label>TIN</label>
              <Input
                onChange={tinValueChangeHandler}
                onBlur={tinInputBlurHandler}
                hasError={tinHasError}
                isValid={tinIsValid}
                isTouched={tinIsTouched}
                enteredValue={tinValue}
                reset={tinReset}
                type="text"
                id="companyInfoName"
              >
                <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
              </Input>
            </div>
            <div className="flex flex-col gap-2 w-full sm:w-1/2">
              <label>VAT</label>
              <Input
                onChange={vatValueChangeHandler}
                onBlur={vatInputBlurHandler}
                hasError={vatHasError}
                isValid={vatIsValid}
                isTouched={vatIsTouched}
                enteredValue={vatValue}
                reset={vatReset}
                type="text"
                id="companyInfoName"
              >
                <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
              </Input>
            </div>
          </div>
          {/*  inputs end */}

          <div className="w-full sticky bottom-2">
            <button
              onClick={submitForm}
              className="bg-green-600 shadow-md h-[50px] rounded-md flex items-center w-full text-white"
            >
              <div className="flex  items-start w-[70px] sm:w-[100px] justify-center">
                <span className="text-[8px] sm:text-[12px] mt-1">ETB</span>
                <p className="text-[16px] sm:text-[25px]">200</p>
              </div>
              <div className="h-[40px] w-[1px] bg-black/20"></div>
              <div className="flex flex-grow justify-center">
                <p className="-ml-[70px] sm:-ml-[100px] text-[16px] sm:text-[18px]">
                  Buy Document Now
                </p>
              </div>
            </button>
          </div>

          {/* Notice */}

          <div className="mt-[30px] flex flex-col gap-2">
            <p className="text-red-600">NOTICE</p>
            <div className="w-full rounded-md border border-red-700/40 bg-red-600/10 p-4 text-red-800 font-bold text-[14px]">
              <p>BID DOCUMENT PURCHASES ARE NON-REFUNDABLE</p>
              <p>ከመግዛትዎ በፊት መረጃዎን ያረጋግጡ ፣ የጨረታ ሰነድ ግዥ የማይመለስ ነው።</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseBidDoc;
