import { useState } from "react";

// icons
import HideEyeIcon from "./icons/HideEye";
import ShowEyeIcon from "./icons/ShowEye";

const Input = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const inputPassword = props.type === "password";
  return (
    <div className="w-full">
      <div
        className={`relative flex items-center border ${
          props.hasError || props.error ? "border-red-400" : "border-slate-300"
        }  ${props.rounded ? props.rounded : "rounded"}  h-[45px] sm:h-[50px]`}
      >
        {props.children}
        <input
          type={showPassword && inputPassword ? "text" : props.type}
          id={props.id}
          className={`block pr-2.5 bg-transparent  w-full h-full text-[14px] md:text-base text-gray-900 bg-transparent ${
            props.rounded ? props.rounded : "rounded-lg"
          }   appearance-none  focus:outline-none focus:ring-0 focus:border-[#664bc7] peer`}
          placeholder=" "
          name={props.id}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.enteredValue}
          style={{
            // Override autofill background color
            WebkitTextFillColor: "initial",
            WebkitBoxShadow: "0 0 0px 1000px white inset",
          }}
        />
        {props.type === "password" && (
          <button onClick={showPasswordHandler}>
            {showPassword ? (
              <ShowEyeIcon styles="w-5 h-5 mx-3 text-slate-600" />
            ) : (
              <HideEyeIcon styles="w-5 h-5 mx-3 text-slate-600" />
            )}
          </button>
        )}
        <label
          htmlFor={props.id}
          className="absolute text-[14px] md:text-base text-gray-500 bg-white  duration-200 transform -translate-y-5 md:-translate-y-6 scale-90  top-2  left-10 md:left-12 z-10 origin-[0] px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90  peer-focus:-translate-y-5 md:peer-focus:-translate-y-6"
        >
          {props.lable}
        </label>
      </div>
      <div className="h-5 w-full flex justify-end">
        <p className={`mt-0.5 text-xs  text-red-500`}>
          {props.error && <span>{props.errorMessage}</span>}
        </p>
      </div>
    </div>
  );
};

export default Input;
