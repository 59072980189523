import React, { useState, useContext } from "react";

// context
import context from "../../store/context";

// hooks
import useInput from "../../hooks/use-Input";

// components
import Input from "../Input";
import PriceSelector from "../PriceSelector";
import AuthenticationButton from "../Buttons/AuthenticationButton";

// icons
import EmailIcon from "../icons/Email";
import LockIcon from "../icons/Lock";
import API_ENDPOINT from "../../config";

const RegistrationStepTwo = (props) => {
  const { selectedPrice, setFinishedRegistration, setToken } =
    useContext(context);

  const checkedPrice = selectedPrice ? selectedPrice : "3";

  const [selectedOption, setSelectedOption] = useState(checkedPrice);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.id);
  };

  const emailValidator = (enteredValue) => {
    // Regular expression for email or phone number validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(enteredValue);
  };
  const passwordValidator = (enteredValue) => {
    return enteredValue.trim().length >= 6;
  };
  const {
    enteredValue: emailValue,
    hasError: emailHasError,
    valueChangeHandler: emailValueChangeHandler,
    inputBlurHandler: emailInputBlurHandler,
    isTouched: emailIsTouched,
    isValid: emailIsValid,
    reset: emailReset,
  } = useInput(emailValidator);

  const {
    enteredValue: passwordValue,
    hasError: passwordHasError,
    valueChangeHandler: passwordValueChangeHandler,
    inputBlurHandler: passwordInputBlurHandler,
    isTouched: passwordIsTouched,
    isValid: passwordIsValid,
    reset: passwordReset,
  } = useInput(passwordValidator);

  // fetch data

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const { phone, fName, lName, cName } = props.inputData;

  var raw = JSON.stringify({
    email: emailValue,
    password: passwordValue,
    phone: phone,
    first_name: fName,
    last_name: lName,
    plan_id: selectedOption,
    company_name: cName,
  });

  const [isSending, setIsSending] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const sumbitUserInfo = () => {
    if (emailIsValid && passwordIsValid) {
      setIsSending(true);
      setHasError(false);
      // validate phone
      fetch(`${API_ENDPOINT}/auth/register`, {
        method: "POST",
        headers: myHeaders,
        body: raw,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            props.onClick();
            props.onCompleteRegistration({
              firstName: fName,
              paymentInfo: data,
            });
            setFinishedRegistration(true);
            localStorage.setItem("token", data.token); // Save token to localStorage
            setToken(data.token);
          } else {
            setHasError(true);
            setErrorMessage(data.errors.email);
          }
          setIsSending(false);
        })
        .catch((err) => {
          setIsSending(false);
          setHasError(true);
          console.log(err);
        });
    }
  };

  return (
    <div className={`flex flex-col w-full`}>
      <div className="flex flex-col pb-3">
        <span className="font-bold text-base text-gray-700">
          የአባልነት ጥቅል ይምረጡ
        </span>
        <span className="text-xs sm:text-sm text-green-600">
          የአመታዊ ጥቅል በመጠቀም አገልግሎቱን በቅናሽ ያግኙ ።
        </span>
      </div>
      <div className="flex flex-col w-full space-y-3">
        <div className="flex grid grid-cols-3 gap-x-3 itmes-center justify-center pb-3">
          <PriceSelector
            id="1"
            onChange={handleOptionChange}
            duration="3 Months"
            active={selectedOption}
            price="980 birr"
          />
          <PriceSelector
            id="3"
            onChange={handleOptionChange}
            duration="1 Year"
            active={selectedOption}
            price="2360 birr"
          />
          <PriceSelector
            id="2"
            onChange={handleOptionChange}
            duration="6 Months"
            active={selectedOption}
            price="1480 birr"
          />
        </div>
        <Input
          onChange={emailValueChangeHandler}
          onBlur={emailInputBlurHandler}
          hasError={emailHasError}
          isValid={emailIsValid}
          isTouched={emailIsTouched}
          enteredValue={emailValue}
          reset={emailReset}
          error={hasError}
          errorMessage={errorMessage}
          type="email"
          id="registrationEmail"
          lable="ኢሜል ያስገቡ"
        >
          <EmailIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
        <Input
          onChange={passwordValueChangeHandler}
          onBlur={passwordInputBlurHandler}
          hasError={passwordHasError}
          isValid={passwordIsValid}
          isTouched={passwordIsTouched}
          enteredValue={passwordValue}
          reset={passwordReset}
          type="password"
          id="registrationPassword"
          lable="ፓስዎርድ ያስገቡ"
        >
          <LockIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
        <AuthenticationButton
          process={isSending}
          onClick={sumbitUserInfo}
          name="ምዝገባዎን ያጠናቁ"
        />
      </div>
    </div>
  );
};

export default RegistrationStepTwo;
