import checked from "../assets/checked.png";

const PackageList = (props) => {
  return (
    <div className="flex items-center">
      <img className="w-4 mr-2" src={checked} alt="checked" />
      <span>{props.title}</span>
    </div>
  );
};

export default PackageList;
