import React, { useState, useEffect, useRef } from "react";

import ArrowIcon from "./icons/Arrow";

const CustomDropdown = ({ options, onSelect, defaultValue, dropdownId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option.name);
    onSelect(option.id, dropdownId);
    toggleDropdown();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="w-full relative flex flex-col justify-center"
      ref={dropdownRef}
    >
      <ArrowIcon
        styles={`w-3.5 h-3.5 text-black transform transition-transform duration-200 ${
          isOpen ? "-rotate-90" : "rotate-90"
        } absolute right-4`}
      />
      <div
        className="border border-blue-100 h-[45px] flex items-center rounded px-5 cursor-pointer bg-blue-50/50"
        onClick={toggleDropdown}
      >
        {selectedOption ? selectedOption : "Select an option"}
      </div>
      {isOpen && (
        <ul className="border border-slate-100 absolute top-full left-0 mt-1 px-5 w-full bg-white z-40 shadow rounded-b-md max-h-[200px] overflow-y-auto divide-y divide-blue-100/50 text-sm">
          <li
            onClick={() => handleOptionClick({ name: "All", id: "" })}
            className="py-2 hover:bg-slate-50/50 bg-white"
          >
            <button>All</button>
          </li>
          {options.map((option) => (
            <li
              key={option.id}
              id={option.id}
              className="py-2 hover:bg-slate-50/50 bg-white"
              onClick={() => handleOptionClick(option)}
            >
              <button className="text-start">{option.name}</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
