import { useState, useContext } from "react";
import context from "../../store/context";
import SaveIcon from "../icons/Save";
import SavedIcon from "../icons/Saved";
import API_ENDPOINT from "../../config";

const SaveUnsave = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const { setSavedCount, setTenders } = useContext(context);
  const [isSaved, setIsSaved] = useState(
    Number(props.isSavedDB) === 1 ? true : false
  );
  const userToken = localStorage.getItem("token");

  const removeTenderById = (id) => {
    setTenders((prevTender) => {
      return prevTender.filter((tender) => tender.id !== id);
    });
  };

  const saveUnsave = () => {
    if (userToken) {
      setIsSaving(true);
      var headers = new Headers();
      headers.append("X-XSRF-TOKEN", "csrf-cookie");
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${userToken}`);

      var requestOptions = {
        method: "GET",
        headers: headers,
        redirect: "follow",
      };

      fetch(`${API_ENDPOINT}/client/save-unsave/${props.id}`, requestOptions)
        .then((response) => {
          if (response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          }
          return response.json();
        })
        .then((result) => {
          setSavedCount(result.count);

          setTenders((prevData) => {
            const updatedArray = prevData.map((item) => {
              if (item.id === props.id) {
                return {
                  ...item,
                  is_saved: Number(item.is_saved) === 0 ? "1" : "0",
                };
              }
              return item;
            });
            return updatedArray;
          });

          setIsSaved(!isSaved);
          setIsSaving(false);

          if (props.currentPath === "/saved") {
            removeTenderById(props.id);
          }
        })
        .catch((error) => {
          setIsSaving(false);
          console.log("error", error);
        });
    } else {
      props.navigate();
    }
  };

  return (
    <button
      onClick={saveUnsave}
      disabled={isSaving}
      className="flex items-center justify-start space-x-2.5 text-[#664bc7] text-xs font-semibold w-[85px] pl-2 py-1 bg-white shadow rounded"
    >
      {isSaving ? (
        <div className="w-4 h-4 sm:w-5 sm:h-5 rounded-full border-t border-indigo-500 animate-spin"></div>
      ) : isSaved && (isSaved || Number(props.isSavedDB) === 1) ? (
        <SavedIcon styles="w-4 h-4 sm:w-5 sm:h-5" />
      ) : (
        <SaveIcon styles="w-4 h-4 sm:w-5 sm:h-5" />
      )}

      {isSaved && (isSaved || Number(props.isSavedDB) === 1) ? (
        <span className="transition-all duration-200">Saved</span>
      ) : (
        <span className="transition-all duration-200">Save</span>
      )}
    </button>
  );
};

export default SaveUnsave;
