import { useEffect, useContext } from "react";
import context from "../../store/context";

import TendersList from "./TendersList";
import NoPreferance from "../../components/cards/NoPreferance";

const MyFeed = () => {
  const {
    selectedCategory,
    setApiPath,
    setSearchParams,
    getCategories,
    getLanguagesAndRegions,
    popularCategories,
    getPopularCategories,
    tendersSrc,
    getSrc,
  } = useContext(context);

  useEffect(() => {
    getCategories("myfeed");
    getLanguagesAndRegions("myfeed");
    if (tendersSrc.length === 0) {
      getSrc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setApiPath("client/myfeed");

    setSearchParams({
      title: "",
      categoryId: selectedCategory,
      regionId: "",
      languageId: "",
      startDate: "",
      endDate: "",
      status: "",
      page: "",
      companyId: "",
      tenderSrc: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setApiPath, setSearchParams]);

  useEffect(() => {
    if (popularCategories.length === 0) {
      getPopularCategories("myfeed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TendersList
      noTendersPlaceholder={<NoPreferance />}
      categorySlider={popularCategories.length !== 0}
      showSearchSidebar={true}
      filter={"myfeed"}
    />
  );
};

export default MyFeed;
