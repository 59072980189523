import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "./icons/Close";

const MultiSelect = ({
  fullist,
  selecteds,
  onValueSelect,
  placeholder,
  id,
  name,
}) => {
  const [selected, setSelected] = useState(selecteds);
  const [list, setList] = useState(fullist);
  const [showList, setShowList] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    const selectedIds = selected.map((item) => item.id);
    const updatedList = fullist.filter(
      (item) => !selectedIds.includes(item.id)
    );
    onValueSelect(selected);
    // console.log("selected", selected, "full list", updatedList);

    setList(updatedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleButtonClick = (item, event) => {
    event.stopPropagation();
    const isSelected = selected.find(
      (selectedItem) => selectedItem.id === item.id
    );
    if (isSelected) {
      setSelected(
        selected.filter((selectedItem) => selectedItem.id !== item.id)
      );
      setList([...list, item]);
    } else {
      setSelected([...selected, item]);
      setList(list.filter((listItem) => listItem.id !== item.id));
    }
  };

  const handleInputChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleComponentClick = (event) => {
    event.stopPropagation();
  };

  const handleRemoveButtonClick = (item, event) => {
    event.stopPropagation();
    setSelected(selected.filter((selectedItem) => selectedItem.id !== item.id));
    setList([...list, item]);
  };

  const handleDocumentClick = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowList(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleInputClick = () => {
    setShowList(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && filterValue === "") {
      // Remove an element from the array
      if (selected.length > 0) {
        const newList = [...selected];
        newList.pop();
        setSelected(newList);
      }
    }
  };

  return (
    <div
      onClick={(event) => {
        handleComponentClick(event);
      }}
      className="border border-[#664bc7]/50 rounded relative w-full"
    >
      <div className={`flex flex-wrap ${selected.length !== 0 && "m-2"}`}>
        {selected.length !== 0 &&
          selected.map((item) => {
            return (
              <div
                className="flex items-center border rounded-full text-sm  text-[#664bc7] font-medium pl-3 pr-1.5 p-1 space-x-2 mr-2 mb-2 bg-[#664bc7]/10"
                key={item.id}
              >
                <p> {item.name}</p>
                <button
                  onClick={(event) => handleRemoveButtonClick(item, event)}
                >
                  <div className="bg-[#664bc7]/5 hover:bg-red-500/10 hover:text-red-500 rounded-full w-5 h-5 flex items-center justify-center">
                    <CloseIcon styles="w-3.5 h-3.5" />
                  </div>
                </button>
              </div>
            );
          })}
      </div>
      <input
        id={id}
        name={name}
        ref={inputRef}
        value={filterValue}
        onClick={handleInputClick}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        type="text"
        className="outline-none h-10  w-full rounded px-2 placeholder:text-center placeholder:text-sm text-center"
        placeholder={`Select ${placeholder}`}
      />
      {showList && (
        <div className="absolute top-full left-0 mt-1 z-40 flex flex-col border border-slate-100 rounded w-full divide-y bg-white divide-slate-50 text-gray-600 font-medium max-h-[200px] overflow-y-auto shadow">
          {list.map((item) => {
            const isSelected = selected.find(
              (selectedItem) => selectedItem.id === item.id
            );

            // Apply the filter condition
            if (item.name.toLowerCase().includes(filterValue.toLowerCase())) {
              return (
                <button
                  className={`hover:bg-[#664bc7] hover:text-white text-sm w-full py-2 ${
                    isSelected ? "bg-slate-50" : ""
                  }`}
                  key={item.id}
                  onClick={(event) => handleButtonClick(item, event)}
                >
                  {item.name}
                </button>
              );
            }

            return null; // Hide the button if it doesn't match the filter
          })}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
