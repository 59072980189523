import React from "react";

import logo from "../assets/logo.svg";

const PrintableInvoice = ({ amount, invoiceNumber }) => {
  const handlePrintPrintableInvoice = () => {
    window.print();
  };

  return (
    <div className="flex flex-col bg-white w-[7in] text-[#664bc7] mx-auto break-inside-avoid shadow-md relative h-[8.9923in] mt-16">
      <div className="w-full absolute top-1 left-0">
        <div className="w-[7in] mx-auto relative z-50 flex justify-between px-2 md:px-0">
          <button>back</button>
          <button
            className="bg-[#664bc7] text-white rounded w-[40%] max-w-[150px] py-2 text-2xl sm:text-lg font-bold"
            onClick={handlePrintPrintableInvoice}
          >
            Print
          </button>
        </div>
      </div>
      <div className="flex flex-col bg-white w-[7in] text-[#664bc7] mx-auto break-inside-avoid shadow-md relative h-[8.9923in] mt-16">
        {/* first */}
        <div className="bg-[#664bc7] flex items-center justify-between mt-12 px-10">
          <div className="flex flex-col space-y-3 text-white">
            <span className="font-bold text-4xl">INVOICE</span>
            <span className="font-semibold text-lg">#{invoiceNumber}</span>
          </div>
          <div className="bg-white py-1 pl-8 pr-2 px-8 flex flex-col items-end w-[60%] text-xs text-base">
            <div className="flex flex-col">
              <img className="w-52" src={logo} alt="ichereta logo" />
              <span>Powered by</span>
              <span>HahuCloud Technologies PLC</span>
            </div>
          </div>
        </div>

        {/* second */}
        <div className="flex items-start justify-between px-5 text-base px-10 my-12">
          <div className="flex flex-col w-[40%]">
            <span className="font-bold">BILL FROM</span>
            <span>HahuCloud Technologies PLC</span>
            <span>0080011366</span>
            <span>Alemnesh Plaza, Bole, Addis Ababa, Ethiopia</span>
          </div>
          <div className="flex justify-end w-[60%] pl-8 pr-2 px-8">
            <div className="flex flex-col">
              <span className="font-bold">BILL TO</span>
              <span>MelaByte Software Cumpani.</span>
              <span>0123456790</span>
              <span>Addis Ababa, Ethiopia</span>
              <span className="text-green-600 font-bold text-2xl mt-10">
                Invoice Paid
              </span>
            </div>
          </div>
        </div>

        {/* third */}
        <table className="text-base w-full">
          <thead className="bg-[#664bc7] py-12 text-white h-12">
            <tr>
              <th className="font-light w-1/4">Description</th>
              <th className="font-light w-1/4">Qty.</th>
              <th className="font-light w-1/4">Unit Price</th>
              <th className="font-light w-1/4">Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center h-12">
              <td>1 Year Subscription</td>
              <td>1</td>
              <td>{amount} birr</td>
              <td>{amount} birr</td>
            </tr>
          </tbody>
        </table>

        {/* fourth */}
        <div className="border-t border-t-[#664bc7] border-opacity-20 mx-8 flex justify-end pt-32 pb-12">
          <div className="flex space-x-5 font-light text-base">
            <div className="flex flex-col space-y-1">
              <span>Sub Total</span>
              <span>VAT (15%)</span>
              <span>Gross Total</span>
            </div>
            <div className="flex flex-col items-end space-y-1">
              <span>3,000.00 ETB</span>
              <span>450.00 ETB</span>
              <span>3,450.00 ETB</span>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="bg-[#664bc7] bg-opacity-20 text-[#664bc7] flex flex-col items-center py-4 space-y-1 absolute bottom-0 left-0 w-full">
          <img className="w-12 sm:w-16" src="./images/logo.svg" alt="" />
          <span className="text-[9px] sm:text-xs">
            Powered by{" "}
            <span className="font-bold">HahuCloud Technologies PLC</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PrintableInvoice;
