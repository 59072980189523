import { Link } from "react-router-dom";
import CheckShield from "../icons/CheckShield";

import AuthenticationButton from "../Buttons/AuthenticationButton";
const ResetPasswordConfirmation = () => {
  return (
    <div className="flex flex-col items-center space-y-5">
      <CheckShield styles="w-24 h-24 text-green-400" />
      <div className="flex flex-col items-center text-green-500 space-y-1 sm:space-y-2.5 mb-12">
        <h1 className="font-semibold text-lg sm:text-2xl mdtext-3xl ">
          Password Changed Successfully!
        </h1>
        <div className="font-normal text-sm sm:w-2/3 text-center text-gray-700">
          Your password has been changed and you are no logged in!
        </div>
      </div>
      <Link to="/tenders">
        <div className="w-[300px]">
          <AuthenticationButton name="Back to Home" />
        </div>
      </Link>
    </div>
  );
};

export default ResetPasswordConfirmation;
