import React from "react";

function DateConverter({ dateString }) {
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    // const seconds = date.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    const hours12 = hours % 12 || 12;

    const formattedHours = hours12.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${month} ${day}, ${year}  ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const formattedDateTime = formatDateTime(dateString);

  return <h1 className="min-w-max">{formattedDateTime}</h1>;
}

export default DateConverter;
