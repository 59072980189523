import "../../shimmer.css";

const PopularCompanyCardSimmer = () => {
  return (
    <div className="w-[250px] h-[200px] flex flex-col items-center rounded-md border border-slate-100">
      <div className="flex flex-grow  justify-center items-center">
        {/* img container */}
        <div className="animate-pulse-gradient flex flex-col flex-grow items-center justify-center w-32 h-20 md:h-16 md:w-40 rounded-md"></div>
      </div>

      <div className="flex flex-col w-full">
        <div className="flex flex-col items-center text-center mb-2.5 space-y-2">
          <span className=" h-3 animate-pulse-gradient rounded-full w-[90%]"></span>
          <span className=" h-2 animate-pulse-gradient rounded-full w-[60%]"></span>
        </div>
        <div className="bg-slate-50 flex justify-center  py-3.5 w-full">
          <div className="h-3 animate-pulse-gradient rounded-full w-[75%] "></div>
        </div>
      </div>
    </div>
  );
};

export default PopularCompanyCardSimmer;
