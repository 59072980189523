import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";

import GreenButton from "../components/Buttons/GreenButton";
import payment_options from "../assets/payment_options.png";

import CheckCircleIcon from "../components/icons/CheckCircle";
import CountdownTimer from "../components/CountdownTimer";
import context from "../store/context";

const Discount = () => {
  const { setToken } = useContext(context);

  const params = useParams();

  const [plansInfo, setPlansInfo] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(3);

  const handleToggle = (planId) => {
    setSelectedPlan(planId);

    console.log(planId);
  };

  const replaceText = (text) => {
    if (!text) return "";
    return text.replace(/Year/g, "ዓመት").replace(/Months/g, "ወር");
  };

  const handleRedirect = (url) => {
    window.location.href = url;
  };

  const [isRequestingPayment, SetIsRequestingPayment] = useState(false);
  const handleSendPrice = () => {
    SetIsRequestingPayment(true);
    // console.log(params.discountId);
    fetch(
      `https://api.ichereta.com/api/discount/${params.discountId}?plan_id=${selectedPlan}`
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res?.token);
        localStorage.setItem("token", res?.token); // Save token to localStorage
        setToken(res?.token);
        handleRedirect(res.pay_url); // res.url later
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    console.log(params.discountId);
    fetch(`https://api.ichereta.com/api/discount/plans/${params.discountId}`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setPlansInfo(res);
      })
      .catch((err) => console.log(err));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="min-h-screen w-full max-w-[600px] flex flex-col items-center bg-[#F5F5F5]">
      {plansInfo?.time_left && (
        <CountdownTimer initialTime={plansInfo?.time_left} />
      )}
      <div className="flex flex-col items-center bg-[#664BC7] w-full text-center text-white pt-4 pb-[90px] gap-4 px-5">
        <p className="text-2xl font-bold">🎉 ታላቅ ቅናሽ 🎉</p>
        <p className="font-bold">
          የ15 ቀን ሙከራ ጊዜ ሲጠቀሙ ለነበሩ ደምበኞቻን ብቻ ያደረግን ሲሆን ዛሬውኑ የiChereta አካውንቶን በማደስ
          አዳዲስ ጨረታዎችን በተለያዩ አማራጮች ያግኙ።
        </p>

        <div className="w-full text-start pl-5 border-t border-t-slate-200/20 pt-2">
          <p>የአባልነት ጥቅል ይምረጡ</p>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full px-5 -mt-[70px] mb-5">
        {plansInfo?.plans?.map((plan) => (
          <div
            onClick={() => handleToggle(plan.id)}
            key={plan.id}
            className={` w-full rounded border border-green-500  relative w-full ${
              selectedPlan === plan.id ? "bg-green-50" : "bg-white"
            } p-4 flex flex-col gap-3`}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                {selectedPlan === plan.id ? (
                  <CheckCircleIcon styles="w-8 h-8 text-green-500" />
                ) : (
                  <div className="w-8 h-8 rounded-full border border-gray-500 relative"></div>
                )}

                <p className="text-lg font-bold">የ{replaceText(plan?.name)}</p>
              </div>
              <p className="text-lg font-bold">
                {plan?.price - plan.discount} ብር
              </p>
            </div>
            <div className="pl-12 flex items-center justify-between ">
              <div className="relative flex items-center justify-center">
                {" "}
                <p className="text-lg font-bold">{plan.price} ብር</p>
                <div className="w-full absolute h-1 bg-red-500/50"></div>
              </div>

              <p className="font-bold text-sm bg-green-500 rounded-md px-2 py-1 text-white">
                የ {plan.discount} ብር ቅናሽ
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="px-5 w-full sticky bottom-0 font-bold ">
        <GreenButton
          onClick={handleSendPrice}
          process={isRequestingPayment}
          name="▶ ክፍያ ይፈፅሙ"
        />
      </div>

      <div className="mt-8 pt-4 border-t border-t-slate-200 gap-8 flex flex-col">
        <p className="font-bold text-center text-gray-500">ቀልጣፋ የክፍያ አማራጮች </p>

        <img
          src={payment_options}
          alt="payment_options "
          className="h-8 grayscale"
        />

        <p className="text-md mt-3 text-center font-medium">
          ለበለጠ መረጃ{" "}
          <a href="tel:+251955305555" className="text-[#664BC7]">
            0955305555
          </a>{" "}
          ይደውሉ!
        </p>
      </div>
    </div>
  );
};

export default Discount;
