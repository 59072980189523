const TwitterIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.styles}
      viewBox="0 0 12 9.746"
    >
      <path
        id="Icon_awesome-twitter"
        dataname="Icon awesome-twitter"
        d="M10.767,5.81c.008.107.008.213.008.32a6.95,6.95,0,0,1-7,7A6.95,6.95,0,0,1,0,12.023a5.088,5.088,0,0,0,.594.03A4.925,4.925,0,0,0,3.647,11,2.464,2.464,0,0,1,1.348,9.3a3.1,3.1,0,0,0,.464.038,2.6,2.6,0,0,0,.647-.084A2.46,2.46,0,0,1,.487,6.838v-.03A2.477,2.477,0,0,0,1.6,7.119,2.463,2.463,0,0,1,.838,3.83,6.991,6.991,0,0,0,5.909,6.4a2.776,2.776,0,0,1-.061-.563A2.462,2.462,0,0,1,10.1,4.157a4.842,4.842,0,0,0,1.561-.594,2.453,2.453,0,0,1-1.081,1.355A4.93,4.93,0,0,0,12,4.538,5.287,5.287,0,0,1,10.767,5.81Z"
        transform="translate(0 -3.381)"
        fill="#4d3899"
      ></path>
    </svg>
  );
};

export default TwitterIcon;
