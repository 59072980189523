import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// context
import context from "../store/context";

// components
import Carousel from "../components/UI/Carousel";
import PopularCompanyCard from "../components/cards/PopularCompany";
import PopularCompanyCardSimmer from "../components/cards/popularCompanySimmer";
import Accordion from "../components/UI/Accordion";

// Section components
import HeadSection from "../components/section/Head";
import PricingSection from "../components/section/Pricing";
import FooterBannerSection from "../components/section/FooterBanner";

// images
import girl from "../assets/girl.png";

// dummy data
import dummyData from "../DUMMY";
import API_ENDPOINT from "../config";

const Homepage = () => {
  const { popularCompanies, setPopularCompanies } = useContext(context);

  useEffect(() => {
    // Check if popularCompanies is empty
    if (popularCompanies.length === 0) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(`${API_ENDPOINT}/company/featured`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setPopularCompanies(result);
        })
        .catch((error) => console.log("error", error));
    }
  }, [popularCompanies, setPopularCompanies]);

  return (
    <div className="w-full flex flex-col items-center">
      <HeadSection />

      <div className="flex flex-col items-center my-16 sm:my-20 md:my-24 space-y-1 sm:space-y-3 text-gray-800">
        <span className="text-[5vw] sm:text-3xl md:text-4xl font-medium">
          Tenders From Popular
          <span className="text-[#664bc7]"> Companies. </span>
        </span>
        <span className="text-[3vw] sm:text-sm">
          THESE ARE SOME OF THE POPULAR COMPANIES
        </span>
      </div>

      <Carousel arrows={true}>
        {popularCompanies.length !== 0
          ? popularCompanies.map((item) => {
              return (
                <PopularCompanyCard
                  to={`company/${item.id}`}
                  key={item.id}
                  amount={item.tenders_count}
                  imgUrl={item.logo}
                  name={item.name}
                />
              );
            })
          : dummyData.map((item) => {
              return <PopularCompanyCardSimmer key={item.id} />;
            })}
      </Carousel>

      <PricingSection />

      <div className="w-full flex items-center justify-center h-60 bg-[#664bc7] my-12 md:my-24 lg:my-40">
        <div className="w-[90%] max-w-[1500px] flex items-center justify-center lg:justify-between  relative">
          <img
            className="hidden lg:inline  lg:left-24 xl:left-32 lg:w-80"
            src={girl}
            alt="a girl sitting with a laptop"
          />
          <div className="flex flex-grow justify-center bg-gradient-to-r from-transparent to-black/5 py-5 rounded-md sm:rounded-xl lg:rounded-full">
            <div className="flex flex-col text-white items-center space-y-3 sm:space-y-4">
              <span className="text-[7vw] sm:text-5xl font-bold">
                ሁሉንም ጨረታ በአንድ ቦታ!
              </span>
              <span className="text-[4vw] sm:text-2xl font-semibold">
                ከመላው ኢትዮጵያ በአንድ ቦታ።
              </span>
              <Link to="/signup">
                <button className="bg-[#ebba5c] hover:bg-yellow-600/80 transition-colors duration-200 py-3 w-52 rounded font-semibold">
                  Subscribe Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <h1 className="text-[5vw] sm:text-3xl md:text-4xl lg:text-[42px] font-medium mt-5 md:mt-0  mb-16 md:mb-20 lg:mb-24">
        Frequently Asked
        <span className="text-[#664bc7]"> Questions.</span>
      </h1>

      <div className="w-[90%] max-w-[1500px] flex justify-center lg:bg-[#f9f8fd]/60 rounded-md lg:py-12">
        <div className="flex flex-col items-center w-full lg:w-[80%] lg:max-w-[1000px]">
          <Accordion question="What is iChereta?">
            iChereta is an online platform that aggregates and delivers tender
            notices from various sources across Ethiopia. We provide a
            one-stop-shop for businesses and individuals seeking to explore
            opportunities in the Ethiopian market.
          </Accordion>
          <Accordion question="What are the sources of tenders?">
            We source information from a variety of authenticated sources such
            as newspapers, magazines, and the websites of relevant
            organizations.
          </Accordion>
          <Accordion question="What payment options are available?">
            We offer two payment options for our subscription packages: bank
            transfer and Telebirr. You can pay through the following banks: -
            Commercial Bank of Ethiopia (CBE) If you have any other questions or
            concerns, please don't hesitate to reach out to us.
          </Accordion>
          <Accordion question="How often is the tender database updated?">
            We collect data from various sources and upload it daily. Our team
            ensures accuracy of the data.
          </Accordion>
        </div>
      </div>
      <FooterBannerSection />
    </div>
  );
};

export default Homepage;
