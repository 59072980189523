import "../../shimmer.css";

const TenderShimmer = () => {
  return (
    <div className="w-full flex flex-col bg-white rounded-md py-4 px-5 space-y-2 shadow text-[#3d5984]">
      <div className="flex flex-col items-start pb-2 border-b border-b-gray-200/80">
        <div className="mb-2 text-sm sm:text-base font-semibold h-2.5 md:h-3 w-full rounded-full animate-pulse-gradient"></div>
        <div className=" mb-2 text-sm sm:text-base font-semibold h-2.5 md:h-3 w-full md:w-[70%] rounded-full animate-pulse-gradient"></div>
        <div className=" mb-2 text-sm sm:text-base font-semibold  h-2.5 md:h-3 w-[60%] rounded-full animate-pulse-gradient md:hidden"></div>
      </div>
      <div className="flex flex-col space-y-2.5 md:space-y-3 py-2 text-xs sm:text-sm">
        <div className="flex items-center space-x-3">
          <span className="font-bold h-2 md:h-2.5 w-[40%] max-w-[200px] rounded-full animate-pulse-gradient"></span>
          <span className="font-bold h-2 md:h-2.5 w-[30%] max-w-[200px] rounded-full animate-pulse-gradient"></span>
        </div>
        <div className="flex items-center space-x-3">
          <span className="font-bold h-2 md:h-2.5 w-[50%] max-w-[150px] rounded-full animate-pulse-gradient"></span>
          <span className="font-bold h-2 md:h-2.5 w-[40%] max-w-[300px] rounded-full animate-pulse-gradient"></span>
        </div>
        <div className="flex items-center space-x-3">
          <span className="font-bold h-2 md:h-2.5 w-[30%] max-w-[170px] rounded-full animate-pulse-gradient"></span>
          <span className="font-bold h-2 md:h-2.5 w-[30%] max-w-[150px] rounded-full animate-pulse-gradient"></span>
        </div>
        <div className="flex items-center space-x-3 ">
          <span className="font-bold h-2 md:h-2.5 w-[20%]  max-w-[100px] rounded-full animate-pulse-gradient"></span>
          <span className="font-bold h-2 md:h-2.5 w-[30%]  max-w-[200px] rounded-full animate-pulse-gradient"></span>
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-between border-t border-t-gray-200/80 pt-2">
        <div className="space-x-6 text-xs sm:text-sm flex  items-center">
          <div className="w-[80px] md:w-[100px] h-[25px] md:h-[30px] rounded animate-pulse-gradient"></div>
          <div className="w-[50px] md:w-[60px] h-[25px] md:h-[30px] rounded animate-pulse-gradient"></div>
        </div>
        <span className="font-bold h-3 w-[70px] md:w-[100px] rounded-full animate-pulse-gradient"></span>
      </div>
    </div>
  );
};

export default TenderShimmer;
