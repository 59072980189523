import { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import context from "../store/context";

import logo from "../assets/logowitht.svg";
import pdfIcon from "../assets/pdf.svg";
import downloaddIcon from "../assets/download.svg";
import checkmarkIcon from "../assets/checkmark.svg";
import API_ENDPOINT from "../config";

const DocPurchaseConfirmation = () => {
  const { userInfo } = useContext(context);

  const params = useParams();

  const downloadDoc = () => {
    const token = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${API_ENDPOINT}/document/${docDetail?.document?.id}/download`,

      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        // Create a new anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = `${docDetail?.document?.title}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.log("error", error));
  };

  const [refresh, setRefresh] = useState(true);
  const [docDetail, setDocDetail] = useState(null);

  useEffect(() => {
    setRefresh(!refresh);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const getDocDetail = () => {
    const token = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_ENDPOINT}/order/${params?.docId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDocDetail(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getDocDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full min-h-screen flex flex-col items-center mb-[30px]">
      <div className="w-full h-[500px] bg-[#ebedfc]"></div>
      <div className=" w-[95%] max-w-[800px]  -mt-[500px] flex flex-col items-center ">
        <div className="py-[30px] flex flex-col items-center gap-5">
          <div className="w-[200px] ">
            <img src={logo} alt="ichereta logo" />
          </div>
          <div className="flex flex-col items-center gap-2">
            <img className="w-16" src={checkmarkIcon} alt="Check Mark Icon" />
            <p className="text-3xl font-bold text-[#4D4664]">
              Bid Document Purchase Completed
              <span className="text-green-500">.</span>
            </p>
            <p className="text-sm font-semibold text-center text-[#4D4664]">
              You’ve successfully purchased a bid document, you can now download
              the document.
            </p>
          </div>
        </div>
        <div className="border border-slate-200 rounded-lg px-[15px] sm:px-[50px] py-[30px] bg-white w-full">
          <div className="mb-[30px] flex flex-col gap-2">
            <p className="font-[500]">Document</p>
            <div className="w-full sm:h-[120px] p-[10px] sm:p-[50px] border border-slate-300 bg-slate-100 rounded-lg flex flex-col sm:flex-row gap-[30px] items-center">
              <img src={pdfIcon} alt="PDF" className="w-[50px]" />

              <div className="h-[120px] w-[1px] bg-slate-300 hidden sm:block"></div>
              <div>
                <p className="font-bold">{docDetail?.document?.title}</p>
                <p className="text-sm">
                  By: {docDetail?.document?.company?.name}
                </p>

                <p className="text-sm">
                  Bid Ref #: {docDetail?.document?.reference_number}
                </p>
                <p className="text-sm">
                  File Size : {docDetail?.document?.file_size}
                </p>
              </div>
            </div>
          </div>

          <button
            onClick={downloadDoc}
            className="bg-gradient-to-r from-[#AD7110] to-[#FFB236] shadow-md h-[50px] rounded-md flex items-center w-full text-white"
          >
            <div className="flex  items-start w-[70px] sm:w-[100px] justify-center">
              <img src={downloaddIcon} alt="D" />
            </div>
            <div className="h-[40px] w-[1px] bg-black/20"></div>
            <div className="flex flex-grow justify-center">
              <p className="-ml-[70px] font-bold sm:-ml-[100px] text-[16px] sm:text-[18px]">
                Download Document
              </p>
            </div>
          </button>

          <hr className=" border-b-[#EFEFEF] border-b-[0.5px] w-full my-6" />

          <div className="flex flex-col items-center  gap-3">
            <div className="flex flex-col items-center">
              <span>PURCHASE SUMMARY</span>
            </div>

            <div className="flex justify-between w-full">
              <div className="flex flex-col items-start">
                <span className="text-[#4D4664] font-semibold">Amount</span>
                <span className="font-[300] text-sm">
                  {docDetail?.invoice?.amount}
                </span>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-[#4D4664] font-semibold">Order No.</span>
                <span className="font-[300] text-sm">
                  {docDetail?.invoice?.invoice_number}
                </span>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex flex-col items-start">
                <span className="text-[#4D4664] font-semibold">
                  Payment Method
                </span>
                <span className="font-[300] text-sm">
                  {docDetail?.invoice?.payment_method?.name}
                </span>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-[#4D4664] font-semibold">
                  Purchase Date
                </span>
                <span className="font-[300] text-sm">
                  {docDetail?.updated_at}
                </span>
              </div>
            </div>
            <hr className=" border-b-[#EFEFEF] border-dashed border-b-[0.5px] w-full my-2" />
            <div className="flex justify-between w-full">
              <div className="flex flex-col items-start">
                <span className="text-[#4D4664] font-semibold">Name</span>
                <span className="font-[300] text-sm">
                  {docDetail?.first_name} {docDetail?.last_name}
                </span>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-[#4D4664] font-semibold">TIN</span>
                <span className="font-[300] text-sm">{docDetail?.TIN}</span>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex flex-col items-start">
                <span className="text-[#4D4664] font-semibold">
                  Company Name
                </span>
                <span className="font-[300] text-sm">
                  {docDetail?.company_name}{" "}
                </span>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-[#4D4664] font-semibold">VAT</span>
                <span className="font-[300] text-sm">
                  {docDetail?.VAT_number}
                </span>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex flex-col items-start">
                <span className="text-[#4D4664] font-semibold">Email</span>
                <span className="font-[300] text-sm">{docDetail?.email}</span>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-[#4D4664] font-semibold">Phone</span>
                <span className="font-[300] text-sm">{docDetail?.phone}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocPurchaseConfirmation;
