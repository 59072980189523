const LinkedInIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.styles}
      viewBox="0 0 12 12"
    >
      <path
        id="Icon_awesome-linkedin-in"
        dataname="Icon awesome-linkedin-in"
        d="M2.686,12H.2V3.989H2.686ZM1.441,2.9A1.448,1.448,0,1,1,2.882,1.442,1.453,1.453,0,0,1,1.441,2.9ZM12,12H9.515V8.1c0-.929-.019-2.121-1.293-2.121-1.293,0-1.492,1.01-1.492,2.054V12H4.244V3.989H6.631V5.082h.035A2.614,2.614,0,0,1,9.019,3.788C11.537,3.788,12,5.446,12,7.6V12Z"
        transform="translate(0 -0.001)"
        fill="#4d3799"
      ></path>
    </svg>
  );
};

export default LinkedInIcon;
