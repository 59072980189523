import React, { useState } from "react";

function ToggleButtons({ handleTogglerState }) {
  const [selectedButton, setSelectedButton] = useState("Tenders");

  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
    handleTogglerState(buttonId);
  };

  return (
    <div className="flex">
      <button
        className={`px-4 py-1 mr-5 border-b  border-b-2 rounded-t-md text-sm ${
          selectedButton === "Tenders"
            ? "text-[#664bc7]  border-b-[#664bc7] bg-[#664bc7]/20"
            : "text-gray-700"
        }`}
        onClick={() => handleButtonClick("Tenders")}
      >
        Tenders
      </button>
      <button
        className={`px-4 py-1 mr-2 border-b  border-b-2 rounded-t-md text-sm ${
          selectedButton === "bidDoc"
            ? "text-[#664bc7]  border-b-[#664bc7] bg-[#664bc7]/20"
            : "text-gray-700"
        }`}
        onClick={() => handleButtonClick("bidDoc")}
      >
        Bid documents
      </button>
    </div>
  );
}

export default ToggleButtons;
