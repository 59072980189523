import { useState } from "react";

// section components
import RegistrationStepOne from "../../components/section/RegistrationStepOne";
import RegistrationStepTwo from "../../components/section/RegistrationStepTwo";
import RegistrationConfirmation from "../../components/section/RegistrationConfirmation";

// components
import RegistrationProgress from "../../components/RegistrationProgress";
import AuthenticationLayout from "./AuthenticationLayout";

// icons
import BackIcon from "../../components/icons/Back";

const SignupPage = () => {
  const [registrationStep, setRegistrationStep] = useState(1);
  const [stepOneData, setStepOneData] = useState({});
  const [stepTwoData, setStepTwoData] = useState({});

  const registrationStepHandler = () => {
    setRegistrationStep((prev) => {
      return prev + 1;
    });
  };

  const getStepOneData = (data) => {
    setStepOneData(data);
  };

  const getStepTwoData = (data) => {
    setStepTwoData(data);
  };

  return (
    <AuthenticationLayout
      button="Log in"
      route="/login"
      message="Already have iChereta Accounts?"
    >
      <div className="flex flex-col items-center  flex-grow w-full">
        <div className="flex flex-col sm:shadow-sm bg-white mt-10 md:mt-20 sm:px-10 sm:pb-10 sm:rounded  w-[90%] max-w-[600px] relative">
          {registrationStep === 2 && (
            <button
              onClick={() => setRegistrationStep(1)}
              className="absolute  hover:bg-indigo-500/5 px-2 rounded top-[7.5%] sm:top-[6%] left-0 sm:left-[8%]"
            >
              <BackIcon styles="w-6 h-6 sm:w-7 sm:h-7  text-indigo-500" />
            </button>
          )}

          <div className="flex flex-col items-center py-8 space-y-6 sm:space-y-7 md:space-y-9">
            <h1 className="text-[#664bc7] text-lg md:text-xl  lg:text-2xl font-medium">
              አካውንት ይክፈቱ
            </h1>
            <div className="w-[90%] max-w-[500px] h-[70px]">
              <RegistrationProgress progress={registrationStep} />
            </div>
          </div>

          <div className="flex justify-center items-start flex-grow w-full -mt-5 md:mt-0 ">
            <div className="w-full bg-white flex flex-col justify-start ">
              {registrationStep === 1 && (
                <RegistrationStepOne
                  onClick={registrationStepHandler}
                  onSubmit={getStepOneData}
                  inputData={stepOneData}
                />
              )}
              {registrationStep === 2 && (
                <RegistrationStepTwo
                  onClick={registrationStepHandler}
                  inputData={stepOneData}
                  onCompleteRegistration={getStepTwoData}
                />
              )}

              {registrationStep === 3 && (
                <RegistrationConfirmation stepTwoData={stepTwoData} />
              )}
            </div>
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default SignupPage;
