import { Link } from "react-router-dom";

const PopularCompanyCard = (props) => {
  return (
    <Link to={props.to}>
      <div className="w-[250px] h-[200px] flex flex-col items-center shadow-md shadow-[#664bc7]/10 rounded-md border border-[#664bc7]/10">
        <div className="flex flex-grow  justify-center items-center">
          <div className="flex flex-col flex-grow items-center justify-center w-32 h-20 md:h-16 md:w-40 ">
            <img
              className="w-full h-full object-contain"
              src={props.imgUrl}
              alt=""
            />
          </div>
        </div>

        <div className="flex flex-col w-full">
          <div className="flex flex-col text-center mb-1">
            <span className="font-semibold text-gray-800">{props.name}</span>
            <span className="text-[10px] text-gray-500">Addis Ababa</span>
          </div>
          <div className="bg-[#f0f7e6] text-center text-green-600 font-bold py-1.5 w-full">
            <span className="">{props.amount} Open Tenders</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PopularCompanyCard;
