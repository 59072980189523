import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import context from "../store/context";

import AuthenticationButton from "../components/Buttons/AuthenticationButton";

import logo from "../assets/logowitht.svg";
import ArrowIcon from "../components/icons/Arrow";

const LoginWithTelegram = () => {
  const { telegramTenderId } = useContext(context);

  const navigate = useNavigate();

  const requestContact = () => {
    const botVersion = window.Telegram.WebApp.version;

    if (Number(botVersion) >= 6.9) {
      window.Telegram.WebApp.requestContact((val) => {
        if (val) {
          navigate(`/tender/telegram/${telegramTenderId}`);
        }
      });
    } else {
      window.Telegram.WebApp.openTelegramLink("https://t.me/iCheretaBot");
    }
  };

  return (
    <div className="w-full min-h-screen flex flex-col items-center space-y-5 justify-center bg-slate-50">
      <img src={logo} className="w-40" alt="ichereta logo" />
      <h1 className="text-2xl font-bold text-gray-700">ሁሉንም ጨረታ በአንድ ቦታ!</h1>
      <div className="w-full flex flex-col items-center">
        <div className="animate-bounce">
          <ArrowIcon styles="w-8 h-8 rotate-90 text-green-600" />
        </div>
        <div className="w-[90%] bg-white rounded-md p-5">
          <AuthenticationButton
            process={false}
            onClick={requestContact}
            name="Login With Telegram"
          />
        </div>
      </div>
    </div>
  );
};

export default LoginWithTelegram;
