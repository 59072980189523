import { NavLink } from "react-router-dom";
const Navigator = (props) => {
  let activeNavigation =
    "text-blue-500 text-base md:underline md:underline-offset-8 ";
  let inActiveNavigation = "text-base text-[#664bc7] hover:text-blue-500";

  return (
    <li onClick={props.onClick} className="flex items-center">
      <NavLink
        to={props.to}
        className={({ isActive, isPending }) =>
          isPending || isActive ? activeNavigation : inActiveNavigation
        }
        end
      >
        {props.children}
      </NavLink>
    </li>
  );
};

export default Navigator;
