import { useState, useEffect } from "react";

// components
import Input from "../Input";
import AuthenticationButton from "../Buttons/AuthenticationButton";

// hooks
import useInput from "../../hooks/use-Input";
import useNewPassword from "../../hooks/use-newPassword";

// icons
import MessageIcon from "../icons/Message";

const OtpVerification = (props) => {
  const otpValidator = (enteredValue) => {
    return enteredValue.trim().length === 4;
  };

  const {
    enteredValue: otpValue,
    hasError: otpHasError,
    valueChangeHandler: otpValueChangeHandler,
    inputBlurHandler: otpInputBlurHandler,
    isTouched: otpIsTouched,
    isValid: otpIsValid,
    reset: otpReset,
  } = useInput(otpValidator);

  var raw = JSON.stringify({
    phone: props.phone,
    code: otpValue,
  });

  const reqParameters = {
    route: "verify-reset-password",
    data: raw,
    case: otpIsValid,
    case2: otpValue.trim().length === 0,
    onClick: props.onClick,
    getCode: props.getCode,
    errorMessage: "Can not be empty",
  };

  const { isSending, hasError, errorMessage, sendRequest } = useNewPassword(
    reqParameters
  );

  // resending
  const [totalSeconds, setTotalSeconds] = useState(60);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  // reset timer for resending
  const resetTimer = () => {
    setButtonEnabled(false);
    setTotalSeconds(60);
  };

  // resend OTP request
  var rawResend = JSON.stringify({
    phone: props.phone,
    for: "reset",
  });

  const resendReqParameters = {
    route: "send-OTP",
    data: rawResend,
    case: true,
    case2: false,
    onClick: resetTimer,
  };

  const { isSending: resending, sendRequest: resendOtp } = useNewPassword(
    resendReqParameters
  );

  useEffect(() => {
    if (totalSeconds > 0) {
      const timer = setInterval(
        () => setTotalSeconds((prevSeconds) => prevSeconds - 1),
        1000
      );
      return () => clearInterval(timer);
    } else {
      setButtonEnabled(true);
    }
  }, [totalSeconds]);

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const formattedTime = `${minutes}:${seconds.toString().padStart(2, "0")}`;

  return (
    <div className="w-full flex flex-col space-y-3 md:space-y-5">
      <div className="flex flex-col items-center text-[#664bc7] space-y-1 sm:space-y-2.5 mb-12">
        <span className="font-semibold text-3xl md:text-[35px]">
          Enter the OTP code
        </span>
        <div className="font-normal text-sm sm:w-2/3 text-center text-gray-700">
          Enter the OTP code we sent to your mobile number in the field below
        </div>
      </div>
      <div className="flex flex-col space-y-3">
        <Input
          onChange={otpValueChangeHandler}
          onBlur={otpInputBlurHandler}
          hasError={otpHasError}
          isValid={otpIsValid}
          isTouched={otpIsTouched}
          enteredValue={otpValue}
          reset={otpReset}
          error={hasError}
          errorMessage={errorMessage}
          type="number"
          id="resetOTP"
          lable="OTP Code"
        >
          <MessageIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="space-y-3 sm:space-y-4 md:space-y-5">
        <div className="flex items-center space-x-2">
          {totalSeconds > 0 && (
            <h1 className="text-gray-600">
              Click button after {formattedTime}
            </h1>
          )}
          <button
            onClick={resendOtp}
            disabled={
              buttonEnabled && resending ? buttonEnabled : !buttonEnabled
            }
            className={`${
              totalSeconds > 0 ? "text-gray-400" : " text-[#664bc7]"
            } text-sm sm:text-base font-medium`}
          >
            Resend Code
          </button>
          {resending && (
            <div className="w-5 h-5 border-t border-indigo-600 rounded-full animate-spin"></div>
          )}
        </div>

        <AuthenticationButton
          process={isSending}
          onClick={sendRequest}
          name="Verify Code"
        />
      </div>
    </div>
  );
};

export default OtpVerification;
