import "./fillBar.css";
import CheckCircleIcon from "./icons/CheckCircle";
import CheckIcon from "./icons/Check";

const RegistrationProgress = (props) => {
  const step2 = props.progress >= 2;
  const step3 = props.progress === 3;
  return (
    <div className="w-full flex items-center justify-center space-x-3">
      <div className="relative flex justify-center">
        {step2 ? (
          <CheckCircleIcon styles="w-7 h-7 text-green-500 text-normal" />
        ) : (
          <div className="w-7 h-7 text-sm md:text-base rounded-full bg-[#664bc7] text-white flex justify-center items-center">
            1
          </div>
        )}

        <div className="absolute top-8 sm:top-9 w-24 text-center">
          <span
            className={`${
              step2 ? "text-green-500" : "text-[#664bc7]"
            }   text-xs sm:text-sm `}
          >
            First Step
          </span>
        </div>
      </div>

      {/* bar */}
      <div className="h-0.5  w-16 sm:w-24  bg-gray-300 rounded relative overflow-hidden">
        <div
          className={`h-full w-full  ${step2 && "bg-green-500 fill-left"}`}
        ></div>
      </div>

      <div className="relative flex justify-center">
        {step3 ? (
          <CheckCircleIcon styles="w-7 h-7 text-green-500 text-normal" />
        ) : (
          <div
            className={`w-7 h-7 text-sm md:text-base rounded-full  text-white grid place-items-center ${
              step2 ? "bg-[#664bc7]" : " bg-gray-300"
            }`}
          >
            2
          </div>
        )}

        <div className="absolute top-8 sm:top-9  w-24 text-center">
          <span
            className={` ${
              props.progress === 2
                ? "text-[#664bc7]"
                : step3
                ? "text-green-500"
                : "text-gray-300"
            }  text-xs sm:text-sm `}
          >
            Second Step
          </span>
        </div>
      </div>

      {/* bar */}
      <div className="h-0.5  w-16 sm:w-24  bg-gray-300 rounded relative overflow-hidden">
        <div
          className={`h-full w-full  ${step3 && "bg-green-500 fill-left"}`}
        ></div>
      </div>

      <div className="relative flex justify-center">
        {step3 ? (
          <CheckIcon styles="w-7 h-7 text-yellow-500 text-normal" />
        ) : (
          <div
            className={`w-7 h-7 text-sm md:text-base rounded-full  text-white grid place-items-center ${
              props.progress === 3 ? "fill-num" : " bg-gray-300"
            }`}
          >
            3
          </div>
        )}
        <div className="absolute top-8 sm:top-9   w-24 text-center">
          <span
            className={` ${
              step3 ? "text-yellow-500" : "text-gray-300"
            }  text-xs sm:text-sm  text-gray-300`}
          >
            Completed
          </span>
        </div>
      </div>
    </div>
  );
};

export default RegistrationProgress;
