import { Link, useLocation } from "react-router-dom";
import logoPlaceholder from "../../assets/companyProfilePlaceholder.jpg";
const CompanyInfo = ({ src, name, id }) => {
  const location = useLocation();

  const telegramdetailPage = location.pathname.startsWith("/tender/telegram");
  return (
    <div className="w-full space-y-3">
      <h1 className="">COMPANY INFORMATION.</h1>
      <div className="w-full space-y-4 sm:space-y-0 p-5 bg-white rounded-md shadow flex flex-wrap flex-col sm:flex-row justify-center sm:justify-between items-center">
        <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row items-center  sm:space-x-5">
          <div className="w-[100px] h-[100px] rounded-full overflow-hidden border border-slate-100 flex justify-center items-center">
            <img src={src ? src : logoPlaceholder} alt="company logo" />
          </div>
          <div className="sm:space-y-2">
            <h1>{name}</h1>
            {/* <h1>{name}</h1> */}
          </div>
        </div>
        {!telegramdetailPage && (
          <div className="h-[60px] flex items-center justify-center min-w-max w-full sm:w-auto">
            <Link
              to={`/company/${id}`}
              className="bg-[#664bc7]/20 text-[#664bc7] w-full max-w-[300px] sm:w-[200px] py-2 rounded text-center"
            >
              See Tenders
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyInfo;
