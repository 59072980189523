import "../../shimmer.css";

const CompanyProfileShimmer = () => {
  return (
    <div className="w-full flex flex-col items-center text-[#3d5984]">
      <div className="w-full h-40  md:h-52 rounded-md animate-pulse-gradient"></div>

      <div className="flex flex-col items-center bg-white -mt-20 rounded-md w-[85%] max-w-[500px] shadow space-y-5">
        <div className="-mt-12 md:-mt-20 w-28 h-28 md:w-36 md:h-36 rounded-full border-[4px] border-slate-50 flex items-center justify-center overflow-hidden animate-pulse-gradient"></div>
        <div className="flex flex-col items-center text-center space-y-3 md:space-y-4 px-5 pb-7 w-full ">
          <div className="font-bold  text-sm md:text-lg h-3.5 w-[80%] animate-pulse-gradient rounded-full"></div>
          <div className="h-3 w-[70%] animate-pulse-gradient rounded-full"></div>
          <div className="rounded-full  h-[30px] w-[150px] animate-pulse-gradient rounded-full"></div>
        </div>
      </div>

      <div className="pt-10 pb-5 text-sm md:text-base w-full flex justify-center">
        <div className="h-3.5 w-[70%] max-w-[400px] animate-pulse-gradient rounded-full"></div>
      </div>
    </div>
  );
};

export default CompanyProfileShimmer;
