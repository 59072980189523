import { useContext } from "react";
import { Link } from "react-router-dom";

//context
import context from "../../store/context";

// images
import whitelogo from "../../assets/logowhite.svg";
import girl from "../../assets/girl.png";

const SideBanner = () => {
  const { finishedRegistration } = useContext(context);
  return (
    <div className="w-full min-h-screen hidden lg:flex flex-col items-center justify-between lg:max-w-[450px] xl:max-w-[550px] 2xl:max-w-[650px]  bg-[#664bc7]  py-6">
      <div className="flex flex-col justify-between w-full h-full">
        <div className="w-full h-[55px] pl-12">
          <Link to={finishedRegistration ? "/tenders" : "/"}>
            <img
              src={whitelogo}
              alt="white logo of ichereta"
              className="w-40"
            />
          </Link>
        </div>
        <div className="flex justify-center">
          <img
            src={girl}
            alt="a girl sitting with her laptop"
            className="w-[80%]"
          />
        </div>
        <div className="flex flex-col items-center font-bold text-sm text-[#beb4e1] w-full">
          <span>© 2023 &mdash; iChereta™</span>
          <span>
            <span className="font-normal">Powered by </span> HahuCloud
            Technologies PLC
          </span>
        </div>
      </div>
    </div>
  );
};

export default SideBanner;
