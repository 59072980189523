import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import context from "../store/context";

import Input from "../components/Input";
import PriceSelector from "../components/PriceSelector";

import useInput from "../hooks/use-Input";

import logo from "../assets/logowitht.svg";
import PersonIcon from "../components/icons/Person";
import PhoneIcon from "../components/icons/Phone";
import API_ENDPOINT from "../config";

const SignupTelegram = () => {
  const { telegramTenderId, telegramUserNumber } = useContext(context);
  const namesValidator = (enteredValue) => {
    return enteredValue.trim().length !== 0;
  };
  const {
    enteredValue: fNameValue,
    hasError: fNameHasError,
    valueChangeHandler: fNameValueChangeHandler,
    inputBlurHandler: fNameInputBlurHandler,
    isTouched: fNameIsTouched,
    isValid: fNameIsValid,
    reset: fNameReset,
  } = useInput(namesValidator, "");
  const [selectedOption, setSelectedOption] = useState("3");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.id);
    console.log(event.target.id);
  };
  const auth_data = encodeURIComponent(window.Telegram.WebApp.initData);
  const MainButton = window.Telegram.WebApp.MainButton;

  const navigate = useNavigate();

  const registerUser = () => {
    MainButton.showProgress();
    var requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `${API_ENDPOINT}/telegram/register?name=${fNameValue}&plan_id=${selectedOption}&data_check_string=${auth_data}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        MainButton.hideProgress();
        console.log(result);
        if (result.status) {
          navigate(`/tender/telegram/${telegramTenderId}`);
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (fNameValue.trim().length !== 0) {
      MainButton.show();
    } else {
      MainButton.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fNameValue]);

  const buttonRef = useRef(null);

  const handleButtonClick = () => {
    buttonRef.current.click();
  };

  useEffect(() => {
    MainButton.setText("ምዝገባዎን ያጠናቁ");
    MainButton.onClick(handleButtonClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-col min-h-screen w-full pt-10 items-center max-w-[500px] px-5 space-y-12">
      <div ref={buttonRef} onClick={registerUser}></div>
      <div className="w-full flex justify-center">
        <img src={logo} alt="ichereta logo " className="w-40" />
      </div>

      <div className="w-full flex flex-col items-center">
        <Input
          onChange={fNameValueChangeHandler}
          onBlur={fNameInputBlurHandler}
          hasError={fNameHasError}
          isValid={fNameIsValid}
          isTouched={fNameIsTouched}
          enteredValue={fNameValue}
          reset={fNameReset}
          type="text"
          id="firstName"
          lable="ስም"
        >
          <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
        <div
          className={`relative flex items-center border border-slate-300 rounded  h-[45px] sm:h-[50px] w-full my-2`}
        >
          <PhoneIcon styles="w-6 h-6 mx-3 text-slate-400" />
          <input
            type="text"
            id="phone"
            className={`block pr-2.5 bg-transparent  w-full h-full text-[14px] md:text-base text-gray-900 bg-transparent rounded-lg appearance-none  focus:outline-none focus:ring-0 focus:border-[#664bc7] peer`}
            placeholder=" "
            value={telegramUserNumber ? telegramUserNumber : ""}
            disabled={true}
            name="phone"
            style={{
              // Override autofill background color
              WebkitTextFillColor: "initial",
              WebkitBoxShadow: "0 0 0px 1000px white inset",
            }}
          />

          <label
            htmlFor="phone"
            className="absolute text-[14px] md:text-base text-gray-500 bg-white  duration-200 transform -translate-y-5 md:-translate-y-6 scale-90  top-2  left-10 md:left-12 z-10 origin-[0] px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-90  peer-focus:-translate-y-5 md:peer-focus:-translate-y-6"
          >
            የሞባይል ቁጥር
          </label>
        </div>

        <div className="w-full flex flex-col space-y-2">
          <div className="mt-2">
            <h1 className="text-gray-700 font-bold">የአባልነት ጥቅል ይምረጡ</h1>
            <p className="text-green-500  text-sm">
              የአመታዊ ጥቅል በመጠቀም አገልግሎቱን በቅናሽ ያግኙ ።
            </p>
          </div>
          <div className="flex grid grid-cols-3 gap-x-3 itmes-center justify-center pb-3 w-full">
            <PriceSelector
              id="1"
              onChange={handleOptionChange}
              duration="3 Months"
              active={selectedOption}
              price="980 birr"
            />
            <PriceSelector
              id="3"
              onChange={handleOptionChange}
              duration="1 Year"
              active={selectedOption}
              price="2360 birr"
            />
            <PriceSelector
              id="2"
              onChange={handleOptionChange}
              duration="6 Months"
              active={selectedOption}
              price="1480 birr"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupTelegram;
