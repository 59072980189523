import { useState, useContext } from "react";
import context from "../../store/context";

import teleBirrLogo from "../../assets/telebirrlogo.svg";
import cbeBirrLogo from "../../assets/cbebirr.png";
import amoleLogo from "../../assets/amolelogo.png";

import "../../shimmer.css";

const RegistrationConfirmation = (props) => {
  const { HandleSubscription, isFetchingInvoice } = useContext(context);
  const { firstName, paymentInfo } = props.stepTwoData;
  const { amount, banks, invoice_number } = paymentInfo;

  const [accountNumber, setAccountNumber] = useState(banks[0].account_number);
  const [accountName, setAccountName] = useState(banks[0].account_name);

  const bankSelector = (event) => {
    const selectedOption = banks.find(
      (bank) => bank.bank_name === event.target.value
    );
    if (selectedOption) {
      const accountNumber = selectedOption.account_number;
      const accountName = selectedOption.account_name;

      setAccountName(accountName);
      setAccountNumber(accountNumber);
    }
  };

  const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = (buttonIndex) => {
    setActiveButton(buttonIndex);
  };

  const getButtonStyle = (buttonIndex) => {
    return activeButton === buttonIndex
      ? "text-white transition-colors duration-200"
      : "";
  };

  return (
    <div className={`text-3xl text-center rounded flex flex-col`}>
      <div className="flex flex-col  text-[#664bc7] space-y-1.5 sm:space-y-3 pb-8">
        <span className="text-xl sm:text-3xl font-bold">
          ሰላም 👋 , {firstName}!
        </span>
        <h1 className=" text-sm xl:text-xl">
          You've
          <span className="text-green-500 font-semibold mx-2">
            successfully
          </span>
          created your iChereta Account.
        </h1>
        <h1 className="text-base  text-gray-600 font-bold px-5">
          የiChereta አካውንቶ አክቲቭ አይደለም ፣ ጨረታዎችን ለመመልከት ክፍያ ይፍጽሙ!
        </h1>
      </div>

      <div className="w-full border border-gray-300 rounded-md relative text-sm p-5">
        <div className="absolute left-6 -top-3.5 bg-white px-2">
          Payment Methods
        </div>

        <div className="w-full bg-white p-1 rounded-full border border-[#3d5984]/50 mb-3">
          <div className="flex items-center relative w-full rounded-full overflow-hidden z-10 text-xs sm:text-sm">
            <button
              className={`px-4 py-2 ${getButtonStyle(
                1
              )} w-1/2 hover:bg-[#3d5984]/5 rounded-full transition-colors duration-200`}
              onClick={() => handleButtonClick(1)}
            >
              Online Payment
            </button>
            <button
              className={`px-4 py-2 ${getButtonStyle(
                2
              )} w-1/2 hover:bg-[#3d5984]/5 rounded-full transition-colors duration-200`}
              onClick={() => handleButtonClick(2)}
            >
              Bank Transfer
            </button>

            {/* Sliding background effect using Tailwind CSS classes */}
            <div
              className="absolute bottom-0 left-0 h-2 bg-[#3d5984] transition-transform ease-in-out duration-200 h-full w-1/2 rounded-full -z-10"
              style={{
                transform: `translateX(${(activeButton - 1) * 100}%)`,
              }}
            />
          </div>
        </div>

        {activeButton === 1 && (
          <div className="flex flex-col space-y-5 p-2 bg-blue-50/30 rounded-md w-full items-start">
            <span>
              <span className="font-bold ">Amount: </span>
              {amount} Birr
            </span>
            <span>
              <span className="font-bold ">Invoice No: </span>#{invoice_number}
            </span>
            <div className="flex items-center justify-center w-full space-x-3">
              <img
                src={teleBirrLogo}
                alt="Telebirr logo"
                className="w-12 sm:w-16"
              />
              <img src={cbeBirrLogo} alt="CBE Birr logo" className="w-12" />
              <img src={amoleLogo} alt="Amole Logo" className="w-12 sm:w-16" />
            </div>
            <button
              onClick={HandleSubscription}
              className="relative animate-button  w-full text-white py-2 rounded text-base font-medium"
            >
              <span>
                Pay Now | <span className="font-bold text-lg">ይክፈሉ!</span>
              </span>

              {isFetchingInvoice && (
                <div className="absolute top-0 left-0 w-full h-full rounded bg-black/40 flex items-center justify-center">
                  <div className=" animate-spin border-t border-white w-5 h-5 rounded-full"></div>
                </div>
              )}
            </button>
          </div>
        )}

        {activeButton === 2 && (
          <div className="flex flex-col space-y-3">
            <select
              className="w-full flex justify-between outline-none py-2 px-2 bg-slate-50 border border-slate-100 rounded-md"
              name=""
              onChange={bankSelector}
            >
              {banks.map((bank) => (
                <option key={bank.id} value={bank.bank_name}>
                  {bank.bank_name}
                </option>
              ))}
            </select>
            <div className="flex flex-col my-4 space-y-2 w-full items-start">
              <span>
                <span className="font-bold ">Name: </span> {accountName}
              </span>
              <span>
                <span className="font-bold ">Account Number: </span>
                {accountNumber}
              </span>
              <span>
                <span className="font-bold ">Amount: </span>
                {amount} Birr
              </span>
              <span>
                <span className="font-bold ">Invoice No: </span>#
                {invoice_number}
              </span>
            </div>
          </div>
        )}

        <div id="Telebirr" className="tabcontent hidden">
          <p>Telebirr content</p>
        </div>

        <div id="CBEBirr" className="tabcontent hidden">
          <p>CBE content</p>
        </div>
      </div>
    </div>
  );
};

export default RegistrationConfirmation;
