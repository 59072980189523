import { useState } from "react";

import React from "react";

const Toggle = ({ isOn, onClick, id }) => {
  const [on, setOn] = useState(isOn === 1 ? true : false);

  const handleClick = () => {
    const newState = !on;
    setOn(newState);
    onClick(newState, id);
  };

  return (
    <div
      id={id}
      className={`w-12 h-6 bg-gray-300 rounded-full p-1 cursor-pointer flex items-center ${
        on ? "bg-green-500" : "bg-gray-300"
      }`}
      onClick={handleClick}
    >
      <div
        className={`w-4 h-4 rounded-full bg-white shadow-md transform transition-transform ${
          on ? "translate-x-6" : "translate-x-0"
        }`}
      />
    </div>
  );
};

export default Toggle;
