import React, { useState, useRef, useEffect } from "react";

const OtpInput = ({ length = 4, onOtpChange }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (index, event) => {
    const value = event.target.value;
    if (value.length <= 1) {
      // Update the OTP state array with the new value
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Automatically move focus to the next input if there's a value
      if (value && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (
      event.key === "Backspace" &&
      !otp[index] &&
      inputRefs.current[index - 1]
    ) {
      // If Backspace is pressed and current input is empty,
      // delete the previous input's value and move focus to it
      event.preventDefault();
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOtp(newOtp);
      inputRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    // Automatically move focus to the next input if there's a value
    const currentIndex = otp.findIndex((digit) => digit === "");
    if (currentIndex > -1 && inputRefs.current[currentIndex]) {
      inputRefs.current[currentIndex].focus();
    }

    // Call the callback function whenever the OTP values change
    onOtpChange(otp);
  }, [otp, onOtpChange]);

  return (
    <div className="space-x-5 flex items-center">
      {otp.map((digit, index) => (
        <input
          key={index}
          id={`otp${index}`}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength="1"
          value={digit}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          className="outline-none border border-[#664bc7]/30 rounded w-12 sm:w-16  h-12 sm:h-16  text-center text-xl font-semibold"
          ref={(ref) => (inputRefs.current[index] = ref)}
        />
      ))}
    </div>
  );
};

export default OtpInput;
