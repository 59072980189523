import { useState } from "react";
// layout
import AuthenticationLayout from "./AuthenticationLayout";

// sections
import ForgotPasswordSection from "../../components/section/ForgotPasswordSection";
import OtpVerification from "../../components/section/OtpVerification";
import ResetPassword from "../../components/section/ResetPassword";
import ResetPasswordConfirmation from "../../components/section/ResetPasswordConfirmation";

const ForgotPassword = () => {
  const [resetPassword, setResetPassword] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpCode, setOtpCode] = useState("");

  const resetPasswordhandler = () => {
    setResetPassword((prev) => {
      return prev + 1;
    });
  };

  const getPhoneNumber = (data) => {
    setPhoneNumber(data);
  };

  const getOtpCode = (data) => {
    setOtpCode(data);
  };
  return (
    <AuthenticationLayout
      button="Sign Up"
      route="/signup"
      message="Don't have an account?"
    >
      <div className="w-full flex  items-center justify-center  flex-grow">
        <div className="w-[90%] max-w-[600px] bg-white flex flex-col sm:shadow-sm  sm:p-10 md:rounded-md space-y-3 md:space-y-5 sm:border-t border-slate-100 -mt-24">
          {resetPassword === 1 && (
            <ForgotPasswordSection
              onClick={resetPasswordhandler}
              process={resetPassword}
              onSubmit={getPhoneNumber}
            />
          )}
          {resetPassword === 2 && (
            <OtpVerification
              onClick={resetPasswordhandler}
              phone={phoneNumber}
              getCode={getOtpCode}
            />
          )}
          {resetPassword === 3 && (
            <ResetPassword
              onClick={resetPasswordhandler}
              phone={phoneNumber}
              code={otpCode}
            />
          )}
          {resetPassword === 4 && <ResetPasswordConfirmation />}
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default ForgotPassword;
