import { useContext } from "react";
import context from "../store/context";

import VerificationLayout from "./VerificationLayout";
const EmailVerification = () => {
  const { userInfo } = useContext(context);
  return (
    <div className="min-h-screen w-full flex justify-center pt-[10%] bg-[#f8f8ff]/50">
      <VerificationLayout contact={userInfo.email} type="Email" path="email" />
    </div>
  );
};

export default EmailVerification;
