import { useContext, useEffect } from "react";
import context from "../store/context";

// import TendersLayout from "./TendersLayout";
import TendersList from "../pages/Tender/TendersList";

const TendersTelegram = () => {
  const {
    // selectedCategory,
    setTelegramApiPath,
    // setSearchParams,
    // searchParams,
  } = useContext(context);

  useEffect(() => {
    setTelegramApiPath("latest-tenders");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.Telegram.WebApp.BackButton.hide();

  return (
    <TendersList
      categorySlider={false}
      showSearchSidebar={false}
      noTendersPlaceholder={<div>No result</div>}
    />
  );
};

export default TendersTelegram;
