import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import context from "../store/context";

// import TendersLayout from "./TendersLayout";

import TendersList from "./Tender/TendersList";
import CompanyProfileCard from "../components/cards/CompanyProfile";

const CompanyDetail = () => {
  const { setApiPath, setSearchParams, setScrollCount } = useContext(context);

  const params = useParams();

  useEffect(() => {
    setApiPath("company/search");
    setScrollCount(1);

    setSearchParams({
      title: "",
      categoryId: "",
      regionId: "",
      languageId: "",
      startDate: "",
      endDate: "",
      status: "",
      page: "",
      companyId: params.companyId,
      tenderSrc: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setApiPath, setSearchParams]);
  return (
    <TendersList
      companyId={params?.companyId}
      showSearchSidebar={true}
      tenderVsBidDoc={true}
      profileCard={<CompanyProfileCard />}
      categorySlider={false}
      filter={""}
    />
  );
};

export default CompanyDetail;
