import { useContext } from "react";
import context from "../store/context";

const SavedCount = () => {
  const { savedCount } = useContext(context);
  return (
    <div className="absolute -right-2 md:-right-4 -top-2 w-5 h-5 text-xs font-medium rounded-full overflow-hidden">
      {savedCount !== 0 && (
        <div className="h-full w-full bg-red-500  flex justify-center items-center text-white transition-all duration-200">
          {savedCount > 9 ? (
            <div>
              <span>9</span>
              <sup>+</sup>
            </div>
          ) : (
            savedCount
          )}
        </div>
      )}
    </div>
  );
};

export default SavedCount;
