import { useContext } from "react";
import context from "../store/context";
import VerificationLayout from "./VerificationLayout";
const PhoneVerification = () => {
  const { userInfo } = useContext(context);

  return (
    <div className="min-h-screen w-full flex justify-center pt-[10%] bg-[#f8f8ff]/50">
      <VerificationLayout
        contact={userInfo.phone}
        type="Phone Number"
        path="phone"
      />
    </div>
  );
};

export default PhoneVerification;
