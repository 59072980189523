import { Link } from "react-router-dom";

import Toggle from "../Toggle";

const ChannelPreferanceCard = ({
  title,
  buttonName,
  description,
  isOn,
  onClick,
  id,
}) => {
  return (
    <div className="w-full rounded px-3 py-5 border border-[#3d5984]/50 flex flex-col space-y-3">
      <div className="flex justify-between">
        <h1>{title}</h1>
        {buttonName && (
          <Link
            to="/myaccount"
            className="text-[#6449c3] bg-[#6449c3]/10  py-1 rounded text-sm w-[120px] flex justify-center"
          >
            {buttonName}
          </Link>
        )}
      </div>
      <div className="text-[13px]">{description}</div>

      <div className="container mx-auto mt-8">
        <Toggle isOn={isOn} onClick={onClick} id={id} />
      </div>
    </div>
  );
};

export default ChannelPreferanceCard;
