import { Link } from "react-router-dom";

// images
import footerLogo from "../../assets/logowhite.svg";

// social icons
import FacebookIcon from "../icons/social/Facebook";
import InstagramIcon from "../icons/social/Instagram";
import LinkedInIcon from "../icons/social/LinkedIn";
import TelegramIcon from "../icons/social/Telegram";
import TiktokIcon from "../icons/social/Tiktok";
import TwitterIcon from "../icons/social/Twitter";

const Footer = () => {
  const getFullYear = () => {
    return new Date().getFullYear();
  };
  return (
    <div className="w-full flex justify-center pt-32 pb-12  bg-gradient-to-r from-[#6449c3] via-[#4a3594] to-[#2f2063] text-white/60">
      <div className="w-[90%] max-w-[1500px] flex flex-col items-center space-y-7">
        <img
          src={footerLogo}
          alt="a white logo of ichereta"
          className="w-24 sm:w-32  opacity-50"
        />

        <div className="flex flex-col items-center font-bold text-sm">
          <span>© 2023 - {getFullYear()} &mdash; iChereta™</span>
          <span>By HahuCloud Technologies PLC</span>
        </div>

        <div className="flex justify-center space-x-4 sm:space-x-5 md:space-x-6">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/iChereta/"
          >
            <div className="rounded-md bg-white/70 p-2">
              <FacebookIcon styles="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6" />
            </div>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://t.me/iChereta"
          >
            <div className="rounded-md bg-white/70 p-2">
              <TelegramIcon styles="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6" />
            </div>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/iChereta_"
          >
            <div className="rounded-md bg-white/70 p-2">
              <InstagramIcon styles="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6" />
            </div>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.tiktok.com/@ichereta"
          >
            <div className="rounded-md bg-white/70 p-2">
              <TiktokIcon styles="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6" />
            </div>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/@iChereta"
          >
            <div className="rounded-md bg-white/70 p-2">
              <TwitterIcon styles="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6" />
            </div>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/iChereta"
          >
            <div className="rounded-md bg-white/70 p-2">
              <LinkedInIcon styles="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6" />
            </div>
          </a>
        </div>

        <div className="flex space-x-3 sm:space-x-5 md:space-x-7 text-[3.2vw] sm:text-sm md:text-lg text-center">
          <Link to="/about" className="min-w-max">
            About Us.
          </Link>
          <Link to="/privacy" className="min-w-max">
            Privacy Policy.
          </Link>
          <Link to="/refund" className="min-w-max">
            Refund Policy.
          </Link>
          <Link to="/TOS" className="min-w-max">
            Terms of Services.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
