import { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";

import context from "../../store/context";

import CheckIcon from "../icons/Check";
import CloseIcon from "../icons/Close";

import icheretaLogo from "../../assets/logo.svg";
import API_ENDPOINT from "../../config";

const PaymentStatus = () => {
  const userToken = localStorage.getItem("token");
  const { HandleSubscription, isFetchingInvoice } = useContext(context);

  const [data, setData] = useState({});
  const { paymentInvoiceId } = useParams();

  const getInvoice = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${API_ENDPOINT}/client/invoice/${paymentInvoiceId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.invoice);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (userToken) {
      getInvoice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paid = Number(data.status) === 1;
  const unpaid = Number(data.status) === 0;
  return (
    <div className="min-h-screen pt-[10%] w-full flex flex-col items-center space-y-10 bg-[#f8f8ff]">
      <img src={icheretaLogo} alt="ichereta logo" className="w-24 sm:w-32" />
      {paid && (
        <div className="shadow w-[95%] max-w-[400px] flex flex-col space-y-3 items-center rounded-md text-2xl font-bold p-5 text-center bg-white">
          <CheckIcon styles="w-16 h-16 text-green-500" />
          <h1 className="text-gray-700">Payment Successful!</h1>
          <p className="text-sm font-normal">
            Please Click the Button Below to <br /> View Latest Tenders.
          </p>
          <Link
            to="/tenders"
            className="text-base font-normal w-full py-2 bg-green-500 hover:bg-green-600 transition-colors duration-200 rounded flex items-center justify-center text-white"
          >
            View Tenders
          </Link>
        </div>
      )}

      {unpaid && (
        <div className="shadow w-[95%] max-w-[400px] flex flex-col space-y-5 items-center rounded-md text-2xl font-bold p-5 text-center bg-white">
          <CloseIcon styles="w-16 h-16 text-red-500" />
          <h1 className="text-gray-700">Unpaid!</h1>
          <p className="text-sm font-normal">
            Please Click the Button Below to <br /> Proceed With Payment.
          </p>
          <button
            onClick={HandleSubscription}
            className="relative text-base font-normal w-full py-2 bg-red-500 hover:bg-red-600 transition-colors duration-200 rounded flex items-center justify-center text-white"
          >
            <span>Pay</span>
            {isFetchingInvoice && (
              <div className="absolute top-0 left-0 w-full h-full rounded bg-black/40 flex items-center justify-center">
                <div className=" animate-spin border-t border-white w-5 h-5 rounded-full"></div>
              </div>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default PaymentStatus;
