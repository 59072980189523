const TiktokIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.styles}
      viewBox="0 0 12.832 14.77"
    >
      <path
        id="Icon_simple-tiktok"
        dataname="Icon simple-tiktok"
        d="M9.108.012C9.914,0,10.714.006,11.514,0a3.833,3.833,0,0,0,1.077,2.566,4.34,4.34,0,0,0,2.609,1.1v2.48a6.587,6.587,0,0,1-2.585-.6,7.6,7.6,0,0,1-1-.572c-.006,1.8.006,3.594-.012,5.385a4.7,4.7,0,0,1-.831,2.425,4.585,4.585,0,0,1-3.637,1.975,4.487,4.487,0,0,1-2.511-.634,4.641,4.641,0,0,1-2.246-3.514c-.012-.308-.018-.615-.006-.917A4.633,4.633,0,0,1,7.748,5.588C7.76,6.5,7.723,7.41,7.723,8.32a2.112,2.112,0,0,0-2.7,1.3,2.441,2.441,0,0,0-.086.991A2.094,2.094,0,0,0,7.1,12.382a2.068,2.068,0,0,0,1.7-.991,1.42,1.42,0,0,0,.252-.652c.062-1.1.037-2.2.043-3.3C9.1,4.96,9.089,2.486,9.108.012Z"
        transform="translate(-2.369)"
        fill="#4a3594"
      ></path>
    </svg>
  );
};

export default TiktokIcon;
