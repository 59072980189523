import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import context from "../../store/context";

import useInput from "../../hooks/use-Input";

import Input from "../Input";
import AuthenticationButton from "../Buttons/AuthenticationButton";

//icons
import PersonIcon from "../icons/Person";
import EmailIcon from "../icons/Email";
import PhoneIcon from "../icons/Phone";
import CheckIcon from "../icons/Check";
import API_ENDPOINT from "../../config";

const PersonalInfo = ({ onSuccess }) => {
  const { userInfo, setUserInfo, isVerified, setIsVerified, VerifyContact } =
    useContext(context);
  const [emailTaken, setEmailTaken] = useState(false);
  const [EmailErrorMessage, setEmailErrorMessage] = useState("");
  const [phoneTaken, setPhoneTaken] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

  const fullNameValidator = (enteredValue) => {
    return enteredValue.trim().length > 2;
  };

  const {
    enteredValue: fullNameValue,
    hasError: fullNameHasError,
    valueChangeHandler: fullNameValueChangeHandler,
    inputBlurHandler: fullNameInputBlurHandler,
    isTouched: fullNameIsTouched,
    isValid: fullNameIsValid,
    reset: fullNameReset,
  } = useInput(fullNameValidator, userInfo.name);

  const emailValidator = (enteredValue) => {
    // Regular expression for email or phone number validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(enteredValue);
  };
  const {
    enteredValue: emailValue,
    hasError: emailHasError,
    valueChangeHandler: emailValueChangeHandler,
    inputBlurHandler: emailInputBlurHandler,
    isTouched: emailIsTouched,
    isValid: emailIsValid,
    reset: emailReset,
  } = useInput(emailValidator, userInfo.email);

  const phoneValidator = (enteredValue) => {
    const phoneRegex = /^(09|\+251|07|\+2517)\d{8}$|^(\+251)\d{9}$/;
    return phoneRegex.test(enteredValue);
  };
  const {
    enteredValue: phoneValue,
    hasError: phoneHasError,
    valueChangeHandler: phoneValueChangeHandler,
    inputBlurHandler: phoneInputBlurHandler,
    isTouched: phoneIsTouched,
    isValid: phoneIsValid,
    reset: phoneReset,
  } = useInput(phoneValidator, userInfo.phone);

  useEffect(() => {
    fullNameReset();
    emailReset();
    phoneReset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const userToken = localStorage.getItem("token");

  const [isUpdating, setIsUpdating] = useState(false);

  const updatePersonalInfo = () => {
    setEmailTaken(false);
    setPhoneTaken(false);
    if (fullNameIsValid && emailIsValid && phoneIsValid) {
      setIsUpdating(true);
      setEmailTaken(false);
      setPhoneTaken(false);
      var headers = new Headers();
      headers.append("X-XSRF-TOKEN", "csrf-cookie");
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${userToken}`);

      var requestOptions = {
        method: "POST",
        headers: headers,
        redirect: "follow",
      };

      fetch(
        `${API_ENDPOINT}/client/account/personal?name=${fullNameValue}&email=${emailValue}&phone=${phoneValue}`,
        requestOptions
      )
        .then((response) => {
          if (response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          }
          return response.json();
        })
        .then((result) => {
          setIsUpdating(false);

          if (result.status) {
            onSuccess(true);
            setTimeout(() => {
              onSuccess(false);
            }, 2000);

            if (userInfo.phone !== phoneValue) {
              setIsVerified({ ...isVerified, phone: false });
            }
            if (userInfo.email !== emailValue) {
              setIsVerified({ ...isVerified, email: false });
            }

            setUserInfo(result);
          } else {
            if (result.errors.email) {
              setEmailErrorMessage(result.errors.email);
              setEmailTaken(true);
            }
            if (result.errors.phone) {
              setPhoneErrorMessage(result.errors.phone);
              setPhoneTaken(true);
            }
          }
        })
        .catch((error) => {
          setIsUpdating(false);
          console.log("error", error);
        });
    }

    if (!emailIsValid) {
      setEmailErrorMessage("Not a valid email.");
      setEmailTaken(true);
    } else {
      setEmailTaken(false);
    }
    if (!phoneIsValid) {
      setPhoneErrorMessage("Not a valid number.");
      setPhoneTaken(true);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="border-b pb-2 mb-4">
        <h1 className="text-lg font-medium">Personal Information</h1>
      </div>
      <div className="flex flex-col space-y-2">
        <h1 className="text-sm">Full Name</h1>

        <Input
          onChange={fullNameValueChangeHandler}
          onBlur={fullNameInputBlurHandler}
          hasError={fullNameHasError}
          isValid={fullNameIsValid}
          isTouched={fullNameIsTouched}
          enteredValue={fullNameValue}
          reset={fullNameReset}
          type="text"
          id="personalInfoName"
        >
          <PersonIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2 text-sm">
          <h1>Email Address</h1>
          {isVerified.email ? (
            <div className="flex items-center space-x-2 text-xs font-medium bg-green-500/10 text-green-700 pl-1 pr-3 rounded-full py-0.5">
              <CheckIcon styles="w-4 h-4" />
              <span>Verified</span>
            </div>
          ) : (
            <Link
              to="/verification/email"
              onClick={() => {
                VerifyContact(`email=${userInfo.email}&for=email`);
              }}
              className="text-blue-500 hover:text-blue-400 rounded font-bold "
            >
              Verify
            </Link>
          )}
        </div>
        <Input
          onChange={emailValueChangeHandler}
          onBlur={emailInputBlurHandler}
          hasError={emailHasError}
          isValid={emailIsValid}
          isTouched={emailIsTouched}
          enteredValue={emailValue}
          reset={emailReset}
          error={emailTaken}
          errorMessage={EmailErrorMessage}
          type="email"
          id="personalInfoEmail"
        >
          <EmailIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2 text-sm">
          <h1>Phone Number</h1>
          {isVerified.phone ? (
            <div className="flex items-center space-x-2 text-xs font-medium bg-green-500/10 text-green-700 pl-1 pr-3 rounded-full py-0.5">
              <CheckIcon styles="w-4 h-4" />
              <span>Verified</span>
            </div>
          ) : (
            <Link
              to="/verification/phone"
              onClick={() => {
                VerifyContact(`phone=${userInfo.phone}&for=phone`);
              }}
              className="text-blue-500 hover:text-blue-400  rounded font-bold "
            >
              Verify
            </Link>
          )}
        </div>

        <Input
          onChange={phoneValueChangeHandler}
          onBlur={phoneInputBlurHandler}
          hasError={phoneHasError}
          isValid={phoneIsValid}
          isTouched={phoneIsTouched}
          enteredValue={phoneValue}
          reset={phoneReset}
          error={phoneTaken}
          errorMessage={phoneErrorMessage}
          type="tel"
          id="personalInfoPhone"
        >
          <PhoneIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="pt-5">
        <AuthenticationButton
          process={isUpdating}
          name="Save Changes"
          onClick={updatePersonalInfo}
        />
      </div>
    </div>
  );
};

export default PersonalInfo;
