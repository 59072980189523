const InstagramIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.styles}
      viewBox="0 0 13.2 13.2"
    >
      <g
        id="Icon_feather-instagram"
        dataname="Icon feather-instagram"
        transform="translate(-2.4 -2.4)"
      >
        <path
          id="Path_49"
          dataname="Path 49"
          d="M6,3h6a3,3,0,0,1,3,3v6a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V6A3,3,0,0,1,6,3Z"
          transform="translate(0 0)"
          fill="none"
          stroke="#4a3594"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        ></path>
        <path
          id="Path_50"
          dataname="Path 50"
          d="M18.029,14.493A3,3,0,1,1,15.5,11.967a3,3,0,0,1,2.526,2.526Z"
          transform="translate(-6.041 -5.955)"
          fill="none"
          stroke="#4a3594"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        ></path>
        <path
          id="Path_51"
          dataname="Path 51"
          d="M26.25,9.75h0"
          transform="translate(-13.95 -4.05)"
          fill="none"
          stroke="#4a3594"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        ></path>
      </g>
    </svg>
  );
};

export default InstagramIcon;
