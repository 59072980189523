// section components
import PricingSection from "../components/section/Pricing";
import FooterBannerSection from "../components/section/FooterBanner";

const PricingPage = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full  pb-12 md:pb-20 lg:pb-24">
        <PricingSection />
      </div>
      <FooterBannerSection />
    </div>
  );
};

export default PricingPage;
