import { useContext } from "react";
import context from "../../store/context";

const CategoryButton = (props) => {
  const { searchIsClicked } = useContext(context);
  return (
    <button
      id={props.id}
      onClick={props.onClick}
      className={`border border-[#664bc7]/30 text-[#664bc7] font-medium ${
        (Number(props.clickedId) === props.id ||
          (Number(props.clickedId) === 0 && props.id === "")) &&
        !searchIsClicked
          ? "bg-[#664bc7] text-white"
          : "bg-white"
      }  pl-4 pr-5 min-w-max rounded py-1.5 flex items-center text-sm  h-[30px] md:h-[35px]`}
    >
      {props.children}
      <span>{props.name}</span>
    </button>
  );
};

export default CategoryButton;
