import "../../shimmer.css";

const ChannelPreferanceShimmer = () => {
  return (
    <div className="w-full rounded px-3 py-5 border border-[#3d5984]/50 flex flex-col space-y-4">
      <div className="flex justify-between">
        <div className="w-[100px] h-4 rounded-full animate-pulse-gradient"></div>
      </div>
      <div className="text-[13px] w-[80%] h-3 rounded-full animate-pulse-gradient"></div>

      <div className="container mx-auto mt-8">
        <div className="h-6 w-16 rounded-full animate-pulse-gradient"></div>
      </div>
    </div>
  );
};

export default ChannelPreferanceShimmer;
