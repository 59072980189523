import "../../shimmer.css";

const CategoryButtonShimmer = () => {
  return (
    <button
      className={`animate-pulse-gradient h-[30px] md:h-[35px] border border-transparent text-transparent min-w-max rounded `}
    >
      <span>Category name</span>
    </button>
  );
};

export default CategoryButtonShimmer;
