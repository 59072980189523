const TelegramIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={props.styles}
      viewBox="0 0 12 10.064"
    >
      <path
        id="Icon_awesome-telegram-plane"
        dataname="Icon awesome-telegram-plane"
        d="M11.967,5.444l-1.811,8.54c-.137.6-.493.753-1,.469L6.4,12.42,5.066,13.7a.693.693,0,0,1-.555.271l.2-2.81L9.824,6.54c.222-.2-.048-.308-.346-.11L3.156,10.411.435,9.559c-.592-.185-.6-.592.123-.876L11.2,4.582C11.7,4.4,12.128,4.692,11.967,5.444Z"
        transform="translate(-0.001 -4.528)"
        fill="#4a3695"
      ></path>
    </svg>
  );
};

export default TelegramIcon;
