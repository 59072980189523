const Refund = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="w-full max-w-[1000px] sm:bg-slate-100 flex flex-col items-center space-y-8 p-4 sm:p-7 md:p-10 my-12 rounded-xl">
        <span className="text-2xl font-bold">Refund Policy</span>
        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">Refund Policy</span>
          <span>
            We at iChereta.com aim to provide the best possible service to our
            customers. However, we understand that there may be instances where
            you may need to request a refund. This policy outlines the terms and
            conditions for requesting a refund.
          </span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">Eligibility for a Refund</span>
          <span>
            You may be eligible for a refund if you meet the following criteria:
          </span>
          <ul>
            <li>
              You request a refund within 30 days from the date of your
              subscription.
            </li>
          </ul>
          <span>
            Please note that this refund policy is not applicable for
            subscribers in Ethiopia who pay in cash or by check at our office
            and obtain a receipt. It is also not applicable for existing members
            who upgrade their existing subscription.
          </span>
        </div>

        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">How to Request a Refund</span>
          <span>
            If you meet the eligibility criteria for a refund, please contact
            our customer support team at support@ichereta.com and provide us
            with your order details.
          </span>
          <span>
            Our team will review your request and process your refund within 7
            business days after receiving your request.
          </span>
        </div>

        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">Refund Process</span>
          <span>
            Once your refund request is approved, we will process your refund
            through the payment method used during your purchase. Please note
            that it may take some time for the refund to reflect in your
            account, depending on your bank or payment provider.
          </span>
        </div>

        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">Contact Us</span>
          <span>
            If you have any questions about our refund policy or need further
            assistance, please contact our customer support team at
            support@ichereta.com.
          </span>
          <span>
            Thank you for choosing iChereta, we appreciate your business and
            trust in our services.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Refund;
