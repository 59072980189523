import { useState, useContext } from "react";
import { Link } from "react-router-dom";

// context
import context from "../../store/context";

import PackageList from "../PackageList";

const PriceCard = (props) => {
  const [priceExpanded, setPriceExpanded] = useState(props.phone);

  const planContentHandler = () => {
    setPriceExpanded(!priceExpanded);
  };

  const { setSelectedPlan } = useContext(context);

  const planSelectionHandler = () => {
    setSelectedPlan(props.id);
  };

  return (
    <div
      className={`flex flex-col w-full max-w-[400px] md:w-full md:max-w-full lg:w-[370px] pb-8  md:pb-12  border border-[#664bc7]/30 bg-white hover:bg-blue-50/5 transition-colors duration-100 text-center space-y-3 rounded-md shadow-md overflow-hidden ${
        props.alert && "lg:scale-110"
      }`}
    >
      {props.alert && (
        <div className="bg-[#664bc7] text-white font-semibold py-4 text-center">
          Buy Yearly and Save 60%
        </div>
      )}
      <div
        className={`flex flex-col md:flex-row  lg:flex-col px-10 space-y-5 sm:space-y-5 md:space-y-0 lg:space-y-8 ${
          !props.alert ? "pt-8 " : "pt-5 lg:pt-0"
        } `}
      >
        <div className="w-full md:w-1/3 lg:w-full md:bg-blue-50/50  lg:bg-transparent md:p-3 lg:p-0 rounded flex flex-col justify-center md:mr-5 lg:mr-0 space-y-5 lg:space-y-7">
          <div className="flex flex-col items-center w-full md:space-y-1">
            <span className="text-xl md:text-2xl font-semibold mt-2 text-gray-700">
              {props.time}
            </span>
            <span className="text-2xl md:text-3xl font-semibold text-gray-800">
              {`${props.price} birr`}
            </span>
          </div>
          <Link to="/signup">
            <button
              onClick={planSelectionHandler}
              className={` py-2 w-full transition-colors duration-200 ${
                props.alert
                  ? "bg-green-600 text-white hover:bg-green-700"
                  : "border border-green-600 rounded text-green-600 hover:bg-green-600 hover:text-white "
              }`}
            >
              Get Started
            </button>
          </Link>
        </div>
        <div className="space-y-3 md:space-y-0 lg:space-y-3 text-sm md:flex md:items-center lg:flex-col md:justify-between lg:items-start  md:w-2/3 lg:w-full">
          <div className="space-y-3">
            <PackageList title={"Unlimited Tender Access"} />
            <PackageList title={"Daily Email Alert"} />
            <PackageList title={"Daily SMS Alert"} />
            <PackageList title={"Daily Telegram Notification"} />
          </div>
          {!priceExpanded && (
            <div className="transition-all duration-300 space-y-3">
              <PackageList title={"Save Tenders"} />
              <PackageList title={"4 Languages"} />
              <PackageList title={"From All Over Ethiopia"} />
              <PackageList title={"Support Service 24/7"} />
            </div>
          )}

          {props.showBtn && (
            <div className="w-full flex justify-start">
              <button onClick={planContentHandler} className="text-[#664bc7]">
                {!priceExpanded ? (
                  <span>
                    Show <span className="text-red-500"> less features </span>
                  </span>
                ) : (
                  "Show all features"
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
