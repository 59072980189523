import { useContext } from "react";
import { useNavigate } from "react-router-dom";

// context
import context from "../../store/context";

// components
import SavedCount from "../SavedCount";

// icons
import FeedIcon from "../icons/Feed";
import SaveIcon from "../icons/Save";
import TenderIcon from "../icons/Tender";
import SearchIcon from "../icons/Search";
const BottomNav = () => {
  const { showDrawerHandler, clickedBtn, setClickedBtn } = useContext(context);

  const navigate = useNavigate();

  const activeNavigation = "bg-[#664bc7]/20 rounded-t-md font-bold";
  const inActiveNavigation = "text-[#664bc7]";

  const clickHandler = (btnId, routePath) => {
    setClickedBtn(btnId);
    navigate(routePath);
  };
  return (
    <ul className="w-full z-50 h-[80px] text-[#664bc7] bg-white md:hidden rounded-t-xl fixed bottom-0 left-0 right-0 flex items-center justify-center grid grid-cols-4  place-items-center pt-2.5 border-t border-t-gray-200">
      <button
        id="Btn1"
        onClick={() => clickHandler("Btn1", "/tenders")}
        className={`flex flex-col items-center w-[75px] rounded-t-md pt-3 pb-2.5 space-y-1 ${
          clickedBtn === "Btn1" ? activeNavigation : inActiveNavigation
        }`}
      >
        <TenderIcon styles="w-6 h-6" />
        <h1 className="text-sm">Tenders</h1>
      </button>

      <button
        id="Btn2"
        onClick={() => clickHandler("Btn2", "/myfeed")}
        className={`flex flex-col items-center w-[75px] rounded-t-md pt-3 pb-2.5 space-y-1 ${
          clickedBtn === "Btn2" ? activeNavigation : inActiveNavigation
        }`}
      >
        <FeedIcon styles="w-6 h-6" />
        <h1 className="text-sm">My Feed</h1>
      </button>

      <button
        id="Btn3"
        onClick={() => clickHandler("Btn3", "/saved")}
        className={`flex flex-col items-center w-[75px] rounded-t-md pt-3 pb-2.5 space-y-1 ${
          clickedBtn === "Btn3" ? activeNavigation : inActiveNavigation
        }`}
      >
        <div className="relative">
          <SavedCount />
          <SaveIcon styles="w-6 h-6" />
        </div>
        <h1 className="text-sm">Saved</h1>
      </button>

      <button
        onClick={showDrawerHandler}
        className="flex flex-col items-center  w-[75px] rounded-t-md  pt-3 pb-2.5 space-y-1"
      >
        <SearchIcon styles="w-6 h-6" />
        <h1 className="text-sm">Search</h1>
      </button>
    </ul>
  );
};

export default BottomNav;
