import { useParams, useNavigate } from "react-router-dom";

import logo from "../assets/logowhite.svg";
import ArrowDown from "../components/icons/ArrowDown";
import AuthenticationButton from "../components/Buttons/AuthenticationButton";
import { useEffect, useState, useContext } from "react";
import context from "../store/context";

const FreeTrial = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { setToken } = useContext(context);

  const [name, setName] = useState("");

  useEffect(() => {
    fetch(`https://api.ichereta.com/api/name/${params.freeId}`)
      .then((res) => res.json())
      .then((res) => {
        setName(res?.name);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isGettingFreeTrial, setIsGettingFreeTrial] = useState(false);
  const getFreeTrial = () => {
    setIsGettingFreeTrial(true);
    fetch(`https://api.ichereta.com/api/trial/${params.freeId}`)
      .then((res) => res.json())
      .then((res) => {
        if (res?.status) {
          localStorage.setItem("token", res?.token); // Save token to localStorage
          setToken(res?.token);
          navigate("/confirmation");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsGettingFreeTrial(false);
      });
  };

  return (
    <div className="min-h-screen w-full max-w-[600px] flex flex-col items-center bg-[#F5F5F5]">
      <div className="bg-[#664BC7] w-full h-[300px] relative flex flex-col items-center pt-5  overflow-clip">
        {" "}
        <div className="h-[30px] w-[200px] bg-yellow-500 -rotate-45 absolute left-[-45px] top-0 px-[32px] text-[10px] flex items-center font-bold ">
          Free For 15 Days
        </div>
        <div className="flex flex-col items-center gap-6 text-white ">
          <img src={logo} alt="logo" className="w-32" />
          <p className="text-2xl font-semibold">🎁 ነፃ የ15 ቀናት የሙከራ ጊዜ። 🎁</p>
          <p className="text-sm">ነፃ ሙከራውን ለማግኘት ከዚህ በታች ያለውን መመሪያ ይከተሉ።</p>
        </div>
      </div>
      <div className="w-[95%] max-w-[550px] bg-white rounded-md -mt-[100px] z-10 relative p-5 flex flex-col gap-6 shadow">
        <div className="flex items-center gap-1 text-xl font-semibold">
          <p>ሰላም ፣</p> {name}
        </div>
        <p className="text-xl font-semibold">
          የ iChereta <span className="text-green-500"> የ15 ቀን ነፃ</span> ሙከራ ጊዜ
          ተጠቃሚ ይሁኑ።{" "}
        </p>

        <p className="text-xl font-semibold">
          ለመጀመር ከታች <u>"ጀምር"</u> የሚለውን በተን ይጫኑ።
        </p>

        <div className="flex flex-col items-center gap-3 text-[#664BC7]">
          <div className="animate-bounce">
            <ArrowDown />
          </div>
          <div className=" border-4 font-bold  border-[#664BC7]/50 w-full text-[20px] rounded-lg">
            <AuthenticationButton
              process={isGettingFreeTrial}
              onClick={getFreeTrial}
              name="ጀምር"
            />
          </div>
        </div>
      </div>

      <div className="pt-4">
        <p className="text-xl font-medium">
          ለበለጠ መረጃ{" "}
          <a href="tel:+251955305555" className="text-[#664BC7]">
            0955305555
          </a>{" "}
          ይደውሉ!
        </p>
      </div>
    </div>
  );
};

export default FreeTrial;
