import React, { useState, useEffect } from "react";

const CountdownTimer = ({ initialTime }) => {
  const [hours, setHours] = useState(parseInt(initialTime.substring(0, 2)));
  const [minutes, setMinutes] = useState(parseInt(initialTime.substring(3, 5)));
  const [seconds, setSeconds] = useState(parseInt(initialTime.substring(6, 8)));

  useEffect(() => {
    const countdown = setInterval(() => {
      // Decrease seconds by 1
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        // Seconds is 0, check minutes
        if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else {
          // Minutes and seconds are 0, check hours
          if (hours > 0) {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          } else {
            // Countdown complete
            clearInterval(countdown);
          }
        }
      }
    }, 1000); // 1000ms = 1 second

    // Cleanup function to clear interval on component unmount
    return () => clearInterval(countdown);
  }, [hours, minutes, seconds]); // useEffect dependencies

  return (
    <div className="h-[75px] bg-red-500 w-full flex items-end justify-between px-5 text-white pb-3">
      <div className="text-xs font-bold sm:text-base pb-1">ቅናሹ ሊያበቃ</div>
      <div className="text-white flex gap-2  text-lg ">
        <div className="flex flex-col gap-2">
          <p className="text-sm text-center font-bold">ሰዓት</p>
          <div className="border font-bold border-white rounded w-[60px] h-[30px] flex items-center justify-center">
            {" "}
            {hours < 10 ? "0" + hours : hours}
          </div>
        </div>
        <div>
          <p className="text-red-500">-</p>
          <div className="font-bold">:</div>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm text-center font-bold">ደቂቃ</p>
          <div className="border font-bold border-white rounded w-[60px] h-[30px] flex items-center justify-center">
            {minutes < 10 ? "0" + minutes : minutes}
          </div>
        </div>
        <div>
          <p className="text-red-500">-</p>
          <div className="font-bold">:</div>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm text-center font-bold">ሰከንድ</p>
          <div className="border font-bold border-white rounded w-[60px] h-[30px] flex items-center justify-center">
            {seconds < 10 ? "0" + seconds : seconds}
          </div>
        </div>
      </div>
      <div className="pb-1 text-xs sm:text-base font-bold">ቀረው!</div>
    </div>
  );
};

export default CountdownTimer;
