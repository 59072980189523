import { useContext } from "react";
//hooks
import useInput from "../../hooks/use-Input";
import useNewPassword from "../../hooks/use-newPassword";

// context
import context from "../../store/context";

// components
import Input from "../Input";
import AuthenticationButton from "../Buttons/AuthenticationButton";

// icons
import LockIcon from "../icons/Lock";

const ResetPassword = (props) => {
  const passwordValidator = (enteredValue) => {
    return enteredValue.trim().length >= 6;
  };

  const {
    enteredValue: passwordValue,
    hasError: passwordHasError,
    valueChangeHandler: passwordValueChangeHandler,
    inputBlurHandler: passwordInputBlurHandler,
    isTouched: passwordIsTouched,
    isValid: passwordIsValid,
    reset: passwordReset,
  } = useInput(passwordValidator);

  const {
    enteredValue: confirmPasswordValue,
    hasError: confirmPasswordHasError,
    valueChangeHandler: confirmPasswordValueChangeHandler,
    inputBlurHandler: confirmPasswordInputBlurHandler,
    isTouched: confirmPasswordIsTouched,
    isValid: confirmPasswordIsValid,
    reset: confirmPasswordReset,
  } = useInput(passwordValidator);

  const { setToken, setFinishedRegistration } = useContext(context);
  const loginUser = (data) => {
    localStorage.setItem("token", data); // Save token to localStorage
    setToken(data);
    setFinishedRegistration(true);
  };

  var raw = JSON.stringify({
    phone: props.phone,
    password: passwordValue,
    code: props.code,
  });

  const reqParameters = {
    route: "reset-password",
    data: raw,
    case:
      passwordIsValid &&
      confirmPasswordIsValid &&
      passwordValue === confirmPasswordValue,
    case2: passwordValue !== confirmPasswordValue,
    onClick: props.onClick,
    loguserIn: loginUser,
    errorMessage: "The passwords does not match.",
  };

  const { isSending, hasError, errorMessage, sendRequest } = useNewPassword(
    reqParameters
  );

  return (
    <div className="w-full flex flex-col space-y-3 md:space-y-5">
      <div className="flex flex-col items-center text-[#664bc7] space-y-1 sm:space-y-2.5 md:space-y-3 mb-12">
        <span className="font-semibold text-3xl md:text-[35px]">
          Lost Password Reset
        </span>
        <span className="font-normal text-sm  text-center text-gray-700">
          Please enter your desired new password in the fields below and click
          on the 'Change Password' button to update your account credentials.
        </span>
      </div>
      <div className="flex flex-col space-y-3">
        <Input
          onChange={passwordValueChangeHandler}
          onBlur={passwordInputBlurHandler}
          hasError={passwordHasError}
          isValid={passwordIsValid}
          isTouched={passwordIsTouched}
          enteredValue={passwordValue}
          reset={passwordReset}
          type="password"
          id="newPassword"
          lable="New Password"
        >
          <LockIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
        <Input
          onChange={confirmPasswordValueChangeHandler}
          onBlur={confirmPasswordInputBlurHandler}
          hasError={confirmPasswordHasError}
          isValid={confirmPasswordIsValid}
          isTouched={confirmPasswordIsTouched}
          enteredValue={confirmPasswordValue}
          reset={confirmPasswordReset}
          error={hasError}
          errorMessage={errorMessage}
          type="password"
          id="confirmNewPassword"
          lable="Confirm New password"
        >
          <LockIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>

      <AuthenticationButton
        process={isSending}
        onClick={sendRequest}
        name="Change Password"
      />
    </div>
  );
};

export default ResetPassword;
