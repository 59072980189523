import { useNavigate } from "react-router-dom";

import noSavedTenders from "../../assets/addSaved.svg";
import zigzagArrow from "../../assets/trend.png";
import AuthenticationButton from "../Buttons/AuthenticationButton";

const NoSavedTenders = () => {
  const navigate = useNavigate();
  const viewAllTenders = () => {
    navigate("/tenders");
  };
  return (
    <div className="w-[95%] max-w-[500px] p-5 sm:p-8 text-[#3d5984] bg-white drop-shadow mt-[20%] md:mt-[10%] rounded-md flex flex-col items-center text-center space-y-5">
      <h1 className="font-medium sm:font-bold text-base sm:text-lg md:text-xl drop-shadow-sm">
        No Saved Tenders
      </h1>
      <div className="w-24 h-24 md:w-32 md:h-32">
        <img src={noSavedTenders} alt="no saved tenders" />
      </div>
      <div className="flex flex-col items-center space-y-2">
        <h1 className="text-xs sm:text-sm">
          Looks like you haven't saved any tenders yet. Browse & save some
          tenders and come back to this page
        </h1>
        <img
          className="w-8 rotate-[130deg]"
          src={zigzagArrow}
          alt="zig zar arrow"
        />
        <AuthenticationButton
          onClick={viewAllTenders}
          name="View All Tenders"
        />
      </div>
    </div>
  );
};

export default NoSavedTenders;
