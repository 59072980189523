import { Link } from "react-router-dom";

// components
import PackageList from "../PackageList";

// images
import zigzagArrow from "../../assets/trend.png";
import headerImg from "../../assets/headerImg.png";

const HeadSection = () => {
  return (
    <div className="w-full flex justify-center bg-blue-50/30">
      <div className="w-[90%] max-w-[1500px]">
        <div className="w-full grid grid-cols-1 md:grid-cols-2">
          <div className="text-[#664bc7] my-12 lg:my-16">
            <span className="text-3xl sm:text-4xl md:text-3xl lg:text-5xl xl:text-6xl font-semibold ">
              Fast & Convenient
              <br />
              Tender Alert Service.
            </span>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 xl:grid-cols-2 my-12 md:my-6 lg:my-12">
              <PackageList title={"Daily Email Alert"} />
              <PackageList title={"4 Languages"} />
              <PackageList title={"Daily Telegram Notification"} />
              <PackageList title={"From All Over Ethiopia"} />
              <PackageList title={"Daily SMS Alert"} />
              <PackageList title={"Unlimited Tender Access"} />
            </div>
            <div>
              <Link to="signup" className="w-full h-full">
                <button className="w-32 sm:w-40 lg:w-48 py-2 md:py-2.5 text-sm sm:text-normal md:text-lg border border-[#664bc7] bg-[#664bc7] hover:bg-[#664bc7]/90 text-white rounded mr-3 md:mr-5">
                  Create Account
                </button>
              </Link>

              <Link to="login">
                <button className="w-24 sm:w-32 py-2 md:py-2.5  border border-[#664bc7] text-[#664bc7] rounded text-sm sm:text-normal md:text-lg hover:bg-transparent bg-white">
                  Login
                </button>
              </Link>
            </div>
            <div className="flex flex-col items-center w-52">
              <img
                className="w-11 -rotate-90"
                src={zigzagArrow}
                alt="a zigzag arrow"
              />
              <span className="text-xs">
                unlimited Tender Access <br />
                Starting from <span>980birr</span>/3 Months
              </span>
            </div>
          </div>
          <div className="hidden md:inline ">
            <div className="w-full h-full   overflow-hidden  flex items-end">
              <img
                className="w-full object-contain "
                src={headerImg}
                alt="a person with glasses on, staring at a pc"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadSection;
