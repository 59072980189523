import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="min-h-screen w-full bg-[#664bc7] flex flex-col items-center justify-center text-5xl text-white font-bold">
      <h1 className="text-[13vw]">404</h1>
      <Link
        to="/"
        className="w-[300px] py-4 text-center rounded bg-white text-[#664bc7] hover:text-white hover:border border-white hover:bg-transparent"
      >
        <h1 className="text-xl"> Go to Homepage</h1>
      </Link>
    </div>
  );
};

export default ErrorPage;
