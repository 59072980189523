// layout
import AuthenticationLayout from "./AuthenticationLayout";

// sections
import LoginForm from "../../components/section/LoginForm";

const LoginPage = () => {
  return (
    <AuthenticationLayout
      button="Sign Up"
      route="/signup"
      message="Don't have an account?"
    >
      <div className="w-full flex  flex-col items-center flex-grow">
        <div className="w-[90%] max-w-[600px] bg-white flex flex-col sm:shadow-sm  sm:p-10 md:rounded-md space-y-3 md:space-y-5 mt-24 sm:mt-10 md:mt-20">
          <LoginForm />
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default LoginPage;
