const About = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="w-full max-w-[1000px] sm:bg-slate-100 flex flex-col space-y-12 p-4 sm:p-7 md:p-10 my-12 rounded-xl">
        <span className="text-2xl font-bold">About us</span>
        <div className="flex flex-col space-y-2">
          <span className="">
            iChereta is a proud product of
            <span className="font-bold">HahuCloud Technologies PLC</span> and
            it’s designed to make the tender discovery process seamless,
            efficient, and tailored to your specific needs.
          </span>
          <span>
            With our expansive network of tender information sources, we bring
            you the most comprehensive selection of tender opportunities from
            across Ethiopia, spanning multiple languages and regions.
          </span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">Our Mission</span>
          <span>
            At iChereta, our mission is to empower businesses and individuals by
            providing them with a user-friendly platform for accessing tender
            opportunities. We believe that by streamlining the tender discovery
            process, we can help foster economic growth and promote a vibrant
            business ecosystem in Ethiopia.
          </span>
        </div>

        <div className="flex flex-col space-y-8">
          <span className="font-bold text-xl">Why Choose iChereta?</span>

          <ol className="list-disc space-y-3 pl-5">
            <li>
              <span className="font-bold">Extensive Coverage:</span>
              We aggregate tender information from a wide range of sources,
              ensuring that you never miss an opportunity that is relevant to
              your business.
            </li>
            <li>
              <span className="font-bold"> Personalized Alerts:</span>
              iChereta's customizable alert system allows you to receive
              notifications through our website, Telegram, SMS, and email,
              ensuring that you stay informed about the latest tenders in your
              preferred manner.
            </li>
            <li>
              <span className="font-bold"> Localized Content:</span>
              Our platform caters to the diverse linguistic and regional needs
              of Ethiopia, offering tender information in multiple languages and
              from every corner of the country.
            </li>
            <li>
              <span className="font-bold"> Time-saving:</span>
              iChereta's tender alert service saves you time and effort by
              automatically delivering the most relevant tender opportunities
              directly to you, eliminating the need for manual searches.
            </li>
            <li>
              <span className="font-bold">Expert Support: </span>
              Our team of dedicated professionals is committed to providing you
              with unparalleled customer service, ensuring that you have all the
              information you need to make informed decisions about tender
              opportunities.
            </li>
          </ol>

          <div className="flex flex-col space-y-2">
            <span className="font-bold text-lg">
              Join the iChereta Community
            </span>
            <span>
              Discover a world of tender opportunities at your fingertips and
              unlock your business's full potential.
            </span>
          </div>
          <span>
            Sign up with iChereta today and stay ahead of the competition with
            the latest tender alerts and notifications tailored to your needs.
            Let iChereta be your partner on the path to success.
          </span>
        </div>
      </div>
    </div>
  );
};

export default About;
