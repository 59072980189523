import cbe from "./assets/CBE.png";
import ethiotel from "./assets/ethiotel.png";
import dashen from "./assets/dashen.jpg";

const dummyData = [
  {
    id: "c1",
    amount: 1,
    img: cbe,
  },
  { id: "c2", amount: 2, img: ethiotel },
  {
    id: "c3",
    amount: 3,
    img: dashen,
  },
  {
    id: "c4",
    amount: 4,
    img: ethiotel,
  },
  {
    id: "c5",
    amount: 5,
    img: cbe,
  },
  {
    id: "c6",
    amount: 6,
    img: dashen,
  },
  {
    id: "c7",
    amount: 7,
    img: cbe,
  },
  {
    id: "c8",
    amount: 8,
    img: ethiotel,
  },
];

export default dummyData;
