import { useState } from "react";

import useInput from "../../hooks/use-Input";

import Input from "../Input";
import AuthenticationButton from "../Buttons/AuthenticationButton";

//icons
import LockIcon from "../icons/Lock";
import API_ENDPOINT from "../../config";

const Security = () => {
  const passwordValidator = (enteredValue) => {
    return enteredValue.trim().length >= 6;
  };

  const {
    enteredValue: oldPasswordValue,
    hasError: oldPasswordHasError,
    valueChangeHandler: oldPasswordValueChangeHandler,
    inputBlurHandler: oldPasswordInputBlurHandler,
    isTouched: oldPasswordIsTouched,
    isValid: oldPasswordIsValid,
    reset: oldPasswordReset,
  } = useInput(passwordValidator);

  const {
    enteredValue: passwordValue,
    hasError: passwordHasError,
    valueChangeHandler: passwordValueChangeHandler,
    inputBlurHandler: passwordInputBlurHandler,
    isTouched: passwordIsTouched,
    isValid: passwordIsValid,
    reset: passwordReset,
  } = useInput(passwordValidator);

  const {
    enteredValue: confirmPasswordValue,
    hasError: confirmPasswordHasError,
    valueChangeHandler: confirmPasswordValueChangeHandler,
    inputBlurHandler: confirmPasswordInputBlurHandler,
    isTouched: confirmPasswordIsTouched,
    isValid: confirmPasswordIsValid,
    reset: confirmPasswordReset,
  } = useInput(passwordValidator);

  const userToken = localStorage.getItem("token");

  const [isUpdating, setIsUpdating] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [doesNotMatch, setDoesNotMatch] = useState(false);
  const [notMatchMessage, setNotMatchMessage] = useState("");

  const updatePersonalInfo = () => {
    if (passwordValue !== "" && passwordValue === confirmPasswordValue) {
      setIsUpdating(true);
      setDoesNotMatch(false);
      setHasError(false);

      var headers = new Headers();
      headers.append("X-XSRF-TOKEN", "csrf-cookie");
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${userToken}`);

      var requestOptions = {
        method: "POST",
        headers: headers,
        redirect: "follow",
      };

      fetch(
        `${API_ENDPOINT}/client/account/security?old_password=${oldPasswordValue}&new_password=${passwordValue}`,
        requestOptions
      )
        .then((response) => {
          if (response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          }
          return response.json();
        })
        .then((result) => {
          setIsUpdating(false);

          if (result.status) {
            oldPasswordReset();
            passwordReset();
            confirmPasswordReset();
          } else {
            setErrorMessage(result.message);
            setHasError(true);
          }
        })

        .catch((error) => {
          setIsUpdating(false);
          console.log("error", error);
        });
    } else {
      setNotMatchMessage("Does not match.");
      setDoesNotMatch(true);
    }
  };
  return (
    <div className="flex flex-col">
      <div className="border-b pb-2 mb-4">
        <h1 className="text-lg font-medium">Change Password</h1>
      </div>
      <div className="flex flex-col space-y-2">
        <h1 className="text-sm">Old Password</h1>
        <Input
          onChange={oldPasswordValueChangeHandler}
          onBlur={oldPasswordInputBlurHandler}
          hasError={oldPasswordHasError}
          isValid={oldPasswordIsValid}
          isTouched={oldPasswordIsTouched}
          enteredValue={oldPasswordValue}
          reset={oldPasswordReset}
          error={hasError}
          errorMessage={errorMessage}
          type="password"
          id="changePasswordOldValue"
        >
          <LockIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="flex flex-col space-y-2">
        <h1 className="text-sm">New Password</h1>
        <Input
          onChange={passwordValueChangeHandler}
          onBlur={passwordInputBlurHandler}
          hasError={passwordHasError}
          isValid={passwordIsValid}
          isTouched={passwordIsTouched}
          enteredValue={passwordValue}
          reset={passwordReset}
          type="password"
          id="changePasswordNewValue"
        >
          <LockIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="flex flex-col space-y-2">
        <h1 className="text-sm">Confirm New Password</h1>
        <Input
          onChange={confirmPasswordValueChangeHandler}
          onBlur={confirmPasswordInputBlurHandler}
          hasError={confirmPasswordHasError}
          isValid={confirmPasswordIsValid}
          isTouched={confirmPasswordIsTouched}
          enteredValue={confirmPasswordValue}
          reset={confirmPasswordReset}
          error={doesNotMatch}
          errorMessage={notMatchMessage}
          type="password"
          id="changePasswordConfirmNewValue"
        >
          <LockIcon styles="w-6 h-6 mx-3 text-slate-400" />
        </Input>
      </div>
      <div className="pt-5">
        <AuthenticationButton
          process={isUpdating}
          name="Save Changes"
          onClick={updatePersonalInfo}
        />
      </div>
    </div>
  );
};

export default Security;
