import logo from "../assets/logowhite.svg";
import google_play_logo from "../assets/google_play.png";
import app_store_logo from "../assets/app_store.png";

import AuthenticationButton from "../components/Buttons/AuthenticationButton";

import { useNavigate } from "react-router-dom";

const FreeTrialConfirmation = () => {
  const navigate = useNavigate();

  const toTendersPage = () => {
    navigate("/tenders");
  };
  return (
    <div className="min-h-screen w-full max-w-[600px] flex flex-col items-center bg-white">
      <div className="bg-[#664BC7] w-full  relative flex flex-col items-center py-5">
        <div className="flex flex-col items-center gap-6 text-white ">
          <img src={logo} alt="logo" className="w-32" />
          <p className="text-2xl font-semibold">🎉 እንኳን ደስ ያላችሁ!  🎉</p>
          <p className="text-sm">የ15 ቀን የ iChereta ሙከራ ጊዜዎ ጀምሯል።</p>
        </div>
      </div>

      <div className="flex flex-col px-5 py-6 items-center gap-10">
        <p className="text-xl text-center">
          ከታች በተቀመጡት አማራጮች ወይም “ጨረታዎችን ይመልከቱ” የሚለውን በመጭን ፣ ለ 15 ቀናት በነፃ የጨረታ
          ማስታወቂያዎችን ማግኘት ይችላሉ።
        </p>
        <div className=" border-4 border-[#664BC7]/50 w-full rounded-lg">
          <AuthenticationButton onClick={toTendersPage} name="ጨረታዎችን ይመልከቱ" />
        </div>
        <div className="flex flex-col items-center border-t border-t-slate-300 w-full">
          <p className="py-5 text-center font-bold">
            የiCheretaን የሞባይል መተግበሪያ ያውርዱ።
          </p>

          <div className="h-[50px] flex justify-evenly bg-white w-full items-center">
            <a href="https://bit.ly/iChereta-APK" target="_black">
              <img
                src={google_play_logo}
                alt="Google play logo"
                className="w-[140px]"
              />
            </a>

            <a href="https://bit.ly/iChereta-IOS" target="_black">
              <img
                src={app_store_logo}
                alt="App store logo"
                className="w-[140px] -mb-1"
              />
            </a>
          </div>

          <p className="border-y border-y-slate-300 font-bold mt-6 py-6 text-center">
            በተጨማሪም iCheretaን በቴሌብር ፣ ሲቢኢ ብር ፣ M-PESA እና ቴሌግራም ላይ ማግኘት ይችላሉ።
          </p>

          <p className="text-xl font-medium py-3">
            ለበለጠ መረጃ{" "}
            <a href="tel:+251955305555" className="text-[#664BC7]">
              0955305555
            </a>{" "}
            ይደውሉ!
          </p>
        </div>
      </div>
    </div>
  );
};

export default FreeTrialConfirmation;
