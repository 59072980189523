import { useContext, useEffect, useState } from "react";
import context from "../../store/context";

import ChannelPreferanceCard from "../cards/ChannelPreferance";
import ChannelPreferanceShimmer from "../cards/ChannelPreferanceShimmer";
import API_ENDPOINT from "../../config";

const ChannelPreferance = () => {
  const userToken = localStorage.getItem("token");
  const { channels, setChannels, userInfo } = useContext(context);
  const { email, sms, telegram } = channels;

  const [send, setSend] = useState(false);

  const saveChange = (value, id) => {
    setSend(true);
    if (id === "telegramToggle") {
      setChannels({ ...channels, telegram: value ? 1 : 0 });
    } else if (id === "smsToggle") {
      setChannels({ ...channels, sms: value ? 1 : 0 });
    } else {
      setChannels({ ...channels, email: value ? 1 : 0 });
    }
  };

  useEffect(() => {
    if (send) {
      var headers = new Headers();
      headers.append("X-XSRF-TOKEN", "csrf-cookie");
      headers.append("Accept", "application/json");
      headers.append("Authorization", `Bearer ${userToken}`);

      var requestOptions = {
        method: "POST",
        headers: headers,
        redirect: "follow",
      };

      fetch(
        `${API_ENDPOINT}/client/setting/save-channel?SMS=${sms}&email=${email}&telegram=${telegram}`,
        requestOptions
      )
        .then((response) => {
          if (response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          }
          return response.json();
        })
        .then((result) => {
          // console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sms, email, telegram]);

  return (
    <div className="w-full flex flex-col space-y-5">
      {telegram !== "" ? (
        <ChannelPreferanceCard
          id="telegramToggle"
          isOn={telegram}
          onClick={saveChange}
          title="Telegram Notification"
          buttonName=""
          description={
            <p>
              Telegram Notification Will be sent to your registered phone
              <span className="font-bold"> {userInfo.phone}</span>
            </p>
          }
        />
      ) : (
        <ChannelPreferanceShimmer />
      )}

      {sms !== "" ? (
        <ChannelPreferanceCard
          id="smsToggle"
          isOn={sms}
          onClick={saveChange}
          title="SMS Notification"
          buttonName="Change Mobile"
          description={
            <p>
              Daily SMS Notification Will be sent to your registered number
              <span className="font-bold"> {userInfo.phone}</span>
            </p>
          }
        />
      ) : (
        <ChannelPreferanceShimmer />
      )}
      {email !== "" ? (
        <ChannelPreferanceCard
          id="emailToggle"
          isOn={email}
          onClick={saveChange}
          title="Email Notification"
          buttonName="Change Email"
          description={
            <p>
              Daily Email Notification Will be sent to your registered email
              <span className="font-bold"> {userInfo.email}</span>
            </p>
          }
        />
      ) : (
        <ChannelPreferanceShimmer />
      )}
    </div>
  );
};

export default ChannelPreferance;
