import { useEffect, useContext, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import context from "../../store/context";

import TenderCard from "../../components/cards/Tender";
import TenderShimmer from "../../components/cards/TenderShimmer";
import TendersLayout from "./TendersLayout";

import dummyData from "../../DUMMY";

import ToggleButtons from "../../components/Toggler";
import TenderDoc from "../../components/cards/TenderDoc";
import API_ENDPOINT from "../../config";
import AdBanner from "../../components/AdBanner";
import FreeTrialAdBanner from "../../components/FreeTrialAdBanner";

const DEBOUNCE_DELAY = 200; // Adjust the delay as needed
const TendersList = ({ tenderVsBidDoc = false, companyId = 1, ...props }) => {
  const {
    userInfo,
    isFetchingInvoice,
    HandleSubscription,
    isFetchingTenders,
    selectedCategory,
    tenders,
    scrollCount,
    setScrollCount,
    fetchLimiter,
    scrollCoordinates,
    setScrollCoordinates,
    setShouldLoadTenders,
  } = useContext(context);

  const scrollContainerRef = useRef(null);
  const scrollPositionRef = useRef(0); // Ref to store scroll position
  const userToken = localStorage.getItem("token");

  const handleScroll = useCallback(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      const isBottom =
        scrollContainer.scrollTop + scrollContainer.clientHeight >=
        scrollContainer.scrollHeight - 300;

      if (isBottom && fetchLimiter && !isFetchingTenders) {
        setScrollCount((prevCount) => prevCount + 1);
      }

      scrollPositionRef.current = scrollContainer.scrollTop; // Update scroll position ref
      setScrollCoordinates(scrollPositionRef);
    }
  }, [setScrollCount, fetchLimiter, isFetchingTenders, setScrollCoordinates]);

  useEffect(() => {
    let timeoutId;

    const debouncedHandleScroll = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleScroll, DEBOUNCE_DELAY);
    };

    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", debouncedHandleScroll);
      scrollContainer.scrollTop = scrollPositionRef.current; // Restore scroll position
    }

    return () => {
      clearTimeout(timeoutId);
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", debouncedHandleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollPositionRef.current = 0; // Reset scroll position
    }
  }, [selectedCategory]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    scrollContainer.scrollTo(0, scrollCoordinates.current);
  }, [scrollCoordinates]);

  useEffect(() => {
    setTimeout(() => setShouldLoadTenders(true), 200);
  }, [setShouldLoadTenders]);

  const location = useLocation();

  const currentPath = location.pathname;

  const [togglerState, setTogglerState] = useState("Tenders");
  const handleTogglerState = (val) => {
    setTogglerState(val);
  };

  const [companyDocs, setCompanyDocs] = useState([]);

  useEffect(() => {
    if (togglerState === "bidDoc") {
      const token = localStorage.getItem("token");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${API_ENDPOINT}/company/${companyId}/documents`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setCompanyDocs(result);
        })
        .catch((error) => console.log("error", error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [togglerState]);

  return (
    <TendersLayout
      showSearchSidebar={props.showSearchSidebar}
      filter={props.filter}
      categorySlider={props.categorySlider}
    >
      <div
        className="w-full space-y-4 overflow-y-auto overflow-x-hidden flex flex-col items-center h-full px-2 py-3 sm:py-5 sm:px-5 pb-4"
        ref={scrollContainerRef}
      >
        {userInfo?.is_active && userInfo?.is_active !== 1 && userToken && (
          <AdBanner
            latestPlan={userInfo?.latest_plan}
            invoiceAmount={userInfo?.amount}
            HandleSubscription={HandleSubscription}
            isFetchingInvoice={isFetchingInvoice}
          />
        )}

        <FreeTrialAdBanner />

        {props.profileCard && props.profileCard}
        {tenderVsBidDoc && (
          <ToggleButtons handleTogglerState={handleTogglerState} />
        )}
        {togglerState === "bidDoc" ? (
          <>
            <div className="grid grid-cols-1  md:grid-cols-2 gap-4 w-full">
              {companyDocs?.map((doc, index) => {
                return (
                  <TenderDoc
                    companyId={companyId}
                    key={index}
                    id={doc?.id}
                    title={doc?.title}
                    price={doc?.price}
                    size={doc?.file_size}
                    refNo={doc?.description}
                    isPurchased={doc?.is_purchased}
                    from="somewhere"
                  />
                );
              })}
            </div>
          </>
        ) : (
          <>
            {isFetchingTenders && scrollCount === 1
              ? dummyData.map((item) => <TenderShimmer key={item.id} />)
              : tenders.length !== 0 &&
                tenders.map((tender, index) => (
                  <TenderCard
                    key={`${tender.id}-${index}`}
                    id={tender.id}
                    title={tender.title}
                    alternativeOpeningDate={tender.alternative_open_date}
                    openingDate={tender.bid_open_date}
                    alternativeClosingDate={tender.alternative_close_date}
                    closingDate={tender.bid_close_date}
                    createdAt={tender.created_at}
                    status={tender.status}
                    isSavedDB={tender.is_saved}
                    route={props.route}
                    currentPath={currentPath}
                    publishedOn={tender.tender_sources}
                  />
                ))}
            {fetchLimiter ? (
              tenders.length !== 0 && <TenderShimmer />
            ) : scrollCount > 1 && tenders.length > 10 ? (
              <h1 className="sm:text-lg text-slate-300">No more content</h1>
            ) : (
              tenders.length === 0 && props.noTendersPlaceholder
            )}
          </>
        )}
      </div>
    </TendersLayout>
  );
};

export default TendersList;
