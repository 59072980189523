import { useState, useEffect, useRef } from "react";

import PlusIcon from "../icons/Plus";
import MinusIcon from "../icons/Minus";

const Accordion = (props) => {
  const accordionRef = useRef();

  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const accordionToggler = () => {
    setAccordionExpanded((prevState) => {
      return !prevState;
    });
  };

  useEffect(() => {
    const accordion = accordionRef.current;

    if (accordionExpanded) {
      accordion.style.maxHeight = `${accordion.scrollHeight}px`;
      accordion.style.opacity = 1;
    } else {
      accordion.style.maxHeight = 0;
      accordion.style.opacity = 0;
    }
  }, [accordionExpanded]);

  return (
    <div
      onClick={accordionToggler}
      className={`w-full pl-4 pr-8 py-4 hover:bg-blue-50/50 shadow transition-colors duration-200 ${
        accordionExpanded
          ? "bg-blue-50/50  space-y-2"
          : "bg-slate-50 lg:bg-white"
      }  rounded-md text-gray-600 mb-3 `}
    >
      <div className="flex items-center space-x-3 cursor-pointer">
        <div className="p-2 rounded-full bg-white">
          {accordionExpanded ? (
            <MinusIcon styles={"w-4 h-4 sm:w-5 sm:h-5 text-gray-600"} />
          ) : (
            <PlusIcon styles={"w-4 h-4 sm:w-5 sm:h-5 text-gray-600"} />
          )}
        </div>
        <div className="font-semibold text-sm sm:text-base md:text-lg">
          {props.question}
        </div>
      </div>
      <div
        ref={accordionRef}
        className={`w-full text-start pl-12 sm:pl-14 md:pl-16 text-[13px] sm:text-sm md:text-base break-all ${
          accordionExpanded ? "" : "max-h-0"
        }  transition-max-height duration-100 overflow-hidden`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Accordion;
