import { useContext } from "react";
import { useLocation } from "react-router-dom";

import context from "../../store/context";

import SearchSidebar from "../../components/section/SearchSidebar";
import CategoriesSlide from "../../components/UI/CategoriesSlide";

const TendersLayout = (props) => {
  const { popularCategoriesRef } = useContext(context);

  const location = useLocation();
  const userToken = localStorage.getItem("token");

  const telegramPage =
    location.pathname.startsWith("/latest-tenders/telegram") ||
    location.pathname.startsWith("/tender/telegram") ||
    location.pathname.startsWith("/myfeed/telegram");

  return (
    <div
      className={`w-full sm:w-[90%] ${
        telegramPage ? "max-w-[600px]" : " max-w-[1500px]"
      } flex items-start sm:relative`}
    >
      {props.showSearchSidebar && (
        <div className=" bg-white w-full sm:w-[350px] pr-5  hidden lg:flex flex-col sticky top-0 z-50 h-screen sm:h-auto sm:top-[73px] md:top-[69px] lg:top-[79px] left-0">
          <SearchSidebar filter={props.filter} redirect={props.redirect} />
        </div>
      )}

      {/* tenders section */}
      <div
        className={`flex flex-col relative items-center justify-start w-full lg:w-[400px] flex-grow bg-[#f8f8ff]/70`}
      >
        <div className="w-full sticky top-[61px] sm:top-[73px] md:top-[69px] lg:top-[79px] left-0 bg-white">
          {props.categorySlider && (
            <CategoriesSlide
              filter={props.filter}
              onResponse={popularCategoriesRef}
            />
          )}
        </div>

        <div
          className={`w-full flex flex-col items-center  ${
            userToken &&
            props.categorySlider &&
            popularCategoriesRef.current.length !== 0
              ? " h-[calc(100vh-191px)] sm:h-[calc(100vh-203px)] md:h-[calc(100vh-129px)] lg:h-[calc(100vh-139px)]"
              : telegramPage
              ? "h-screen"
              : " h-[calc(100vh-141px)] sm:h-[calc(100vh-153px)] md:h-[calc(100vh-69px)] lg:h-[calc(100vh-79px)]"
          } `}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default TendersLayout;
