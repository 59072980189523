import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// context
import ContextProvider from "./store/ContextProvider";

// pages
import Rootlayout from "./pages/Rootlayout";
import Homepage from "./pages/Home";
import LoginPage from "./pages/Auth/Login";
import SignupPage from "./pages/Auth/Signup";
import TendersPage from "./pages/Tender/Tenders";
import TenderDetail from "./pages/Tender/TenderDetail";
import PricingPage from "./pages/Pricing";
import FAQPage from "./pages/FAQ";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import MyFeed from "./pages/Tender/MyFeed";
import SavedTenders from "./pages/Tender/Saved";
import MyAccount from "./pages/Tender/MyAccount";
import BillingPage from "./pages/Tender/Billing";
import SettingsPage from "./pages/Tender/Settings";
import ErrorPage from "./pages/Error";
import CompanyDetail from "./pages/CompanyDetail";
import PhoneVerification from "./pages/PhoneVerification";
import EmailVerification from "./pages/EmailVerification";
import Invoice from "./pages/Tender/Invoice";
import PaymentStatus from "./components/cards/PaymentStatus";

import About from "./pages/About";
import Privacy from "./pages/Privacy";
import AndroidAppPrivacy from "./pages/AndroidAppPrivacy";
import Refund from "./pages/Refund";
import Tos from "./pages/Tos";

import TendersTelegram from "./Telegram/TendersTelegram";
import TenderDetailTelegram from "./Telegram/TenderDetailTelegram";
import MyFeedTelegram from "./Telegram/MyFeedTelegram";
import LoginWithTelegram from "./Telegram/LoginWithTelegram";
import SignupTelegram from "./Telegram/SignupTelegram";

import PurchaseBidDoc from "./pages/PurchaseBidDoc";
import DocPurchaseConfirmation from "./pages/DocPurchaseConfirmation";
import FreeTrial from "./pages/FreeTrial";
import FreeTrialConfirmation from "./pages/FreeTrialConfirmation";
import Discount from "./pages/Discount";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Rootlayout />,
      errorElement: <ErrorPage />,
      children: [
        { index: true, element: <Homepage /> },
        { path: "login", element: <LoginPage /> },
        { path: "signup", element: <SignupPage /> },
        { path: "forgot-password", element: <ForgotPassword /> },

        { path: "tenders", element: <TendersPage /> },
        { path: "tender/:tenderId", element: <TenderDetail /> },
        { path: "latest-tenders/telegram", element: <TendersTelegram /> },
        { path: "login/telegram", element: <LoginWithTelegram /> },

        { path: "signup/telegram", element: <SignupTelegram /> },
        {
          path: "tender/telegram/:telegramTenderId",
          element: <TenderDetailTelegram />,
        },

        { path: "myfeed/telegram", element: <MyFeedTelegram /> },

        { path: "company/:companyId", element: <CompanyDetail /> },
        { path: "payments/:paymentInvoiceId", element: <PaymentStatus /> },

        { path: "pricing", element: <PricingPage /> },
        { path: "FAQ", element: <FAQPage /> },
        { path: "myfeed", element: <MyFeed /> },
        { path: "saved", element: <SavedTenders /> },
        { path: "myaccount", element: <MyAccount /> },
        { path: "billing", element: <BillingPage /> },
        { path: "settings", element: <SettingsPage /> },
        { path: "verification/phone", element: <PhoneVerification /> },
        { path: "verification/email", element: <EmailVerification /> },
        { path: "invoice/:invoiceId", element: <Invoice /> },
        { path: "free/:freeId", element: <FreeTrial /> },
        { path: "trial/:freeId", element: <FreeTrial /> },
        { path: "confirmation", element: <FreeTrialConfirmation /> },
        { path: "discount/:discountId", element: <Discount /> },

        { path: "about", element: <About /> },
        { path: "privacy", element: <Privacy /> },
        { path: "refund", element: <Refund /> },
        { path: "TOS", element: <Tos /> },

        { path: "/app/about", element: <About /> },
        { path: "/app/privacy", element: <AndroidAppPrivacy /> },
        { path: "/app/faq", element: <FAQPage /> },
        { path: "/app/tos", element: <Tos /> },
      ],
    },
    { path: "purchasedoc/:docId", element: <PurchaseBidDoc /> },
    {
      path: "docpurchaseconfirmation/:docId",
      element: <DocPurchaseConfirmation />,
    },
  ]);

  const currentPath = window.location.pathname;
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (
      token &&
      (currentPath === "/" ||
        currentPath.startsWith("/signup") ||
        currentPath.startsWith("/login") ||
        currentPath.startsWith("/forgot-password"))
    ) {
      router.navigate("/tenders");
    }

    if (currentPath === "/register") {
      router.navigate("/signup");
    }

    if (currentPath === "/client/my-feed") {
      router.navigate("/myfeed");
    }

    const handleFeedPath = () => {
      if (currentPath.startsWith("/myfeed/telegram")) {
        return false;
      } else if (currentPath.startsWith("/myfeed")) {
        return true;
      }
    };

    if (
      !token &&
      (currentPath.startsWith("/settings") ||
        currentPath.startsWith("/billing") ||
        currentPath.startsWith("/myaccount") ||
        handleFeedPath() ||
        currentPath.startsWith("/saved") ||
        currentPath.startsWith("/settings") ||
        currentPath.startsWith("/verification") ||
        currentPath.startsWith("/phone") ||
        currentPath.startsWith("/email") ||
        currentPath.startsWith("/invoice") ||
        currentPath.startsWith("/payments"))
    ) {
      router.navigate("/login");
    }
  }, [router, token, currentPath]);

  useEffect(() => {
    const handleRouteChange = () => {
      window.scrollTo(0, 0); // Scroll to top of the page
    };

    router.subscribe(handleRouteChange);
  }, [router]);

  return (
    <ContextProvider>
      <RouterProvider router={router} />
    </ContextProvider>
  );
}

export default App;
