const Privacy = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="flex-grow w-full max-w-[1000px] sm:bg-slate-100 flex flex-col space-y-8 p-4 sm:p-7 md:p-10 my-12 rounded-xl">
        <span className="text-2xl font-bold">Privacy Policy</span>
        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">Privacy Policy for iChereta</span>
          <span>
            At iChereta, we are committed to protecting the privacy of our
            users. This Privacy Policy outlines how we collect, use, and share
            your personal information when you visit our website.
          </span>
        </div>
        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">
            Information Collection and Use
          </span>
          <span>
            We collect personal information such as your name and email address
            when you register for an account on our website. We may also collect
            information about your browsing activity on our website, such as
            your IP address, the pages you visit, and the time and date of your
            visit.
          </span>
          <span>
            We use this information to improve our website and provide you with
            a personalized experience. We may also use your email address to
            send you updates about our website, promotions, and other news.
          </span>
        </div>

        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">Cookies</span>
          <span>
            We use cookies to collect information about your browsing activity
            on our website. Cookies are small data files that are stored on your
            device when you visit our website. They help us to remember your
            preferences and provide you with a better browsing experience.
          </span>
        </div>

        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">Third-Party Services</span>
          <span>
            We may use third-party services such as Google Analytics to collect
            information about your browsing activity on our website. These
            services may use cookies and other tracking technologies to collect
            information about your use of our website.
          </span>
        </div>

        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">Information Sharing</span>
          <span>
            We do not sell, trade, or rent your personal information to third
            parties. We may share your information with our service providers
            who help us to operate our website and provide you with a better
            experience.
          </span>
        </div>

        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">Security</span>
          <span>
            We take reasonable measures to protect your personal information
            from unauthorized access, use, or disclosure. However, no method of
            transmission over the internet or electronic storage is completely
            secure.
          </span>
        </div>

        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">
            Changes to this Privacy Policy
          </span>
          <span>
            We reserve the right to modify this Privacy Policy at any time. If
            we make any material changes to this Privacy Policy, we will notify
            you by email at support@ichereta.com or by posting a notice on our
            website.
          </span>
        </div>

        <div className="flex flex-col space-y-2">
          <span className="font-bold text-lg">Security</span>
          <span>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at support@ichereta.com.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
