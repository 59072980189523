import { useNavigate } from "react-router-dom";
import pdfIcon from "../../assets/pdf.svg";
import API_ENDPOINT from "../../config";
import { useState } from "react";

const TenderDoc = ({
  id,
  title,
  price,
  size,
  refNo,
  from,
  isPurchased,
  companyId,
}) => {
  const navigate = useNavigate();

  const handleGoToPurchase = (id) => {
    navigate(`/purchasedoc/${id}`);
  };

  const [isDownloading, setIsDownloading] = useState(false);
  const handleDownload = (id) => {
    setIsDownloading(true);
    const token = localStorage.getItem("token");

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${API_ENDPOINT}/document/${id}/download`,

      requestOptions
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        // Create a new anchor element
        const a = document.createElement("a");
        a.href = url;
        a.download = `${title}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setIsDownloading(false);
      });
  };

  return (
    <div className="bg-white shadow flex flex-col rounded-md p-3 justify-between gap-2">
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-start">
          <div className="w-[60px] min-w-max bg-[#F0EDF9] p-3 rounded-lg">
            <img src={pdfIcon} alt="PDF" className="w-[30px]" />
          </div>
          <div className="font-bold text-[#3D5984]">
            <p className="break-words">{title}</p>
          </div>
        </div>
        <hr className="border-t border-t-slate-200" />
        <div className="flex flex-col">
          <div className="flex gap-2">
            <div className="w-[60px] font-bold text-[#3D5984]">Price</div>
            <div className="text-green-500">{price} ETB</div>
          </div>
          <div className="flex gap-2">
            <div className="w-[60px] font-bold text-[#3D5984]">Size</div>
            <div className=" text-[#3D5984]">{size}</div>
          </div>
          <div className="flex gap-2">
            <div className="w-[60px] font-bold text-[#3D5984]">Ref No.</div>
            <div className=" text-[#3D5984]">{refNo}</div>
          </div>
        </div>
      </div>
      <button
        onClick={
          isPurchased
            ? () => {
                handleDownload(id);
              }
            : price === "0"
            ? () => {
                handleDownload(id);
              }
            : () => {
                handleGoToPurchase(id);
              }
        }
        className={`h-[40px] rounded ${
          isPurchased || price === "0"
            ? "bg-green-600 text-white"
            : "bg-indigo-500 text-white"
        } relative`}
      >
        {isPurchased || price === "0" ? "Download Document" : "Buy Document"}
        {isDownloading && (
          <div className="absolute w-full h-full flex justify-center items-center top-0 left-0 bg-black/50">
            <div className="w-[30px] h-[30px] rounded-full border-t border-t-white animate-spin"></div>
          </div>
        )}
      </button>
    </div>
  );
};

export default TenderDoc;
